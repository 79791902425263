<div class="container mb-20">
  <h2 class="inline-flex text-18 md:text-22 text-primary font-700 mb-6">Primary button</h2>
  <div class="border-t border-neutral-400 mb-10 mt-2"></div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn btn__primary">Btn primary</button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnPrimary }}</code>
      </pre>
    </div>
  </div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn btn__primary" disabled>Btn primary</button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnPrimaryDisabled }}</code>
      </pre>
    </div>
  </div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn btn__primary">
        <span>Btn primary</span>
        <fa-icon [icon]="['fal', 'arrow-right']" class="ml-3"></fa-icon>
      </button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnPrimaryIcon }}</code>
      </pre>
    </div>
  </div>
</div>

<div class="container mb-20">
  <h2 class="inline-flex text-18 md:text-22 text-primary font-bold mb-6">Secondary buttons</h2>
  <div class="border-t border-neutral-400 mb-10 mt-2"></div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn btn__secondary">Btn secondary</button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnSecondary }}</code>
      </pre>
    </div>
  </div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn btn__secondary" disabled>Btn secondary</button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnSecondaryDisabled }}</code>
      </pre>
    </div>
  </div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn btn__secondary">
        <span>Btn secondary</span>
        <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
      </button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnSecondaryIcon }}</code>
      </pre>
    </div>
  </div>
</div>

<div class="container mb-10">
  <h2 class="inline-flex text-18 md:text-22 font-bold mb-2">Link buttons</h2>
  <div class="border-t border-neutral-400 mb-10 mt-2"></div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn__link">Btn link</button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnLink }}</code>
      </pre>
    </div>
  </div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn__link" disabled>Btn link</button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnLinkDisabled }}</code>
      </pre>
    </div>
  </div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn__link">
        <span>Btn link</span>
        <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
      </button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnLinkIcon }}</code>
      </pre>
    </div>
  </div>
</div>

<div class="container mb-10">
  <h2 class="inline-flex text-18 md:text-22 font-bold mb-2">Special link buttons</h2>
  <div class="border-t border-neutral-400 mb-10 mt-2"></div>
  <div class="flex flex-col md:flex-row mb-8">
    <div class="w-full md:w-12/24">
      <button class="btn__link--special group">
        <fa-icon [icon]="['fal', 'arrow-right']" class="btn__link--special-icon"></fa-icon>
        <span>Btn link</span>
      </button>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnSpecialLinkIcon }}</code>
      </pre>
    </div>
  </div>
</div>




