import { AfterViewInit, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ContentModuleBaseComponent } from '../content-module-base.component';
import { ContentModule } from '../../models/content-module.model';
import { isPlatformBrowser } from '@angular/common';
import Swiper, { Navigation, Pagination } from 'swiper';

@Component({
  selector: 'app-content-module-a032',
  templateUrl: './content-module-a032.component.html'
})
export class ContentModuleA032Component implements ContentModuleBaseComponent, AfterViewInit {

  @Input() contentModule: ContentModule;

  constructor(
    @Inject(PLATFORM_ID) public platformId
  ) {
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const swiper = new Swiper('.swiper-module-a32', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 16,
        loop: this.contentModule?.slotables?.length > 1,
        watchOverflow: true,
        navigation: {
          nextEl: '.nav-right-a32',
          prevEl: '.nav-left-a32',
        },
        pagination: {
          el: '.swiper-pagination-a32',
          clickable: true,
        },
      });
    }
  }

}
