import {Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID} from '@angular/core';
import {MenuItem} from '../../../@modules/menu/models/menu-item.model';
import {LocaleService} from '../../../Library/services/locale.service';
import {filter, take, tap} from 'rxjs/operators';
import {DetailService} from '../../../@modules/detail/services/detail.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  currentYear: number;
  locale: string;
  apiParams: any;
  footerMenu: MenuItem[];
  legalMenu: MenuItem[];
  isMobileLayout: boolean = null;

  constructor(
    private localeService: LocaleService,
    private detailService: DetailService,
    @Inject(LOCALE_ID) public localeCode: string,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.locale = this.localeService.getLocale();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobileLayout = window.innerWidth <= 768;
      window.addEventListener('resize', () => {
        this.isMobileLayout = window.innerWidth <= 768;
      });
    }

    this.currentYear = new Date().getFullYear();

    this.detailService.details$.pipe(
      filter(result => result != null),
      tap(result => {
        this.footerMenu = result.additional.menus.footer.items;
      }),
      take(1)
    ).subscribe();
  }
}
