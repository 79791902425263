import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { filter, take, tap } from 'rxjs/operators';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import { Platform } from '../../platform/services/platform.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  headerMenu: MenuItem[];

  loading: boolean = null;
  isPlatformMobile: boolean = null;

  constructor(
    private detailService: DetailService,
    private platform: Platform,
    @Inject(LOCALE_ID) public localeCode
  ) {
  }

  ngOnInit(): void {
    this.isPlatformMobile = this.platform.isMobile();

    this.detailService.details$.pipe(
      filter(result => result != null),
      tap(result => {
        this.headerMenu = result.additional.menus.main.items;
      }),
      take(1)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
