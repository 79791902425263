<div class="container mb-20">
  <h2 class="inline-flex text-18 md:text-22 text-primary 2ont-bold mb-6">Input Fields</h2>
  <div class="border-t border-neutral-400 mb-10 mt-2"></div>
  <div class="flex flex-col md:flex-row mb-8 space-x-4">
    <div class="w-full md:w-12/24">
      <div class="ds-input-field__wrapper">
        <label class="ds-field-label" for="ds-field-1">
          Input field label <span class="text-error"><sup>*</sup></span>
        </label>
        <input
          class="ds-field-input"
          id="ds-field-1"
          name="ds-field-1"
          type="text"
          autocomplete="off"
          placeholder="Input field placeholder">
        <span class="ds-field-msg">Input field disclaimer</span>
      </div>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ inputFieldRegular }}</code>
      </pre>
    </div>
  </div>
  <div class="flex flex-col md:flex-row mb-8 space-x-4">
    <div class="w-full md:w-12/24">
      <div class="ds-input-field__wrapper">
        <label class="ds-field-label" for="ds-field-2">
          Input field label <span class="text-error"><sup>*</sup></span>
        </label>
        <input
          class="ds-field-input"
          id="ds-field-2"
          name="ds-field-2"
          type="text"
          disabled
          autocomplete="off"
          placeholder="Input field placeholder">
        <span class="ds-field-msg">Input field disclaimer</span>
      </div>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ inputFieldDisabled }}</code>
      </pre>
    </div>
  </div>
  <div class="flex flex-col md:flex-row mb-8 space-x-4">
    <div class="w-full md:w-12/24">
      <div class="ds-input-field__wrapper has-error">
        <label class="ds-field-label" for="ds-field-3">
          Input field label <span class="text-error"><sup>*</sup></span>
        </label>
        <input
          class="ds-field-input"
          id="ds-field-3"
          name="ds-field-3"
          type="text"
          autocomplete="off"
          placeholder="Input field placeholder">
        <span class="ds-field-msg">Input field disclaimer</span>
      </div>
    </div>
    <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ inputFieldError }}</code>
      </pre>
    </div>
  </div>

  <div class="container mb-20">
    <h2 class="inline-flex text-18 md:text-22 text-primary 2ont-bold mb-6">Select Fields</h2>
    <div class="border-t border-neutral-400 mb-10 mt-2"></div>

    <div class="flex flex-col md:flex-row mb-8 space-x-4">
      <div class="w-full md:w-12/24">
        <div class="ds-input-field__wrapper">
          <label class="ds-field-label" for="ds-field-select">
            Input field label <span class="text-error"><sup>*</sup></span>
          </label>
          <select name="ds-field-select" id="ds-field-select" class="ds-field-input">
            <option>One</option>
            <option>Two</option>
            <option>Three</option>
          </select>
          <span class="ds-field-msg">Input field disclaimer</span>
        </div>
      </div>
      <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ selectFieldRegular }}</code>
      </pre>
      </div>
    </div>

    <div class="flex flex-col md:flex-row mb-8 space-x-4">
      <div class="w-full md:w-12/24">
        <div class="ds-input-field__wrapper">
          <label class="ds-field-label" for="ds-field-select">
            Input field label <span class="text-error"><sup>*</sup></span>
          </label>
          <select disabled name="ds-field-select-2" id="ds-field-select-2" class="ds-field-input">
            <option>One</option>
            <option>Two</option>
            <option>Three</option>
          </select>
          <span class="ds-field-msg">Input field disclaimer</span>
        </div>
      </div>
      <div class="w-full md:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ selectFieldDisabled }}</code>
      </pre>
      </div>
    </div>

    <div class="flex flex-col md:flex-row mb-8 space-x-4">
      <div class="w-full md:w-12/24">
        <div class="ds-input-field__wrapper has-error">
          <label class="ds-field-label" for="ds-field-select">
            Input field label <span class="text-error"><sup>*</sup></span>
          </label>
          <select name="ds-field-select-3" id="ds-field-select-3" class="ds-field-input">
            <option>One</option>
            <option>Two</option>
            <option>Three</option>
          </select>
          <span class="ds-field-msg">Input field disclaimer</span>
        </div>
      </div>
      <div class="w-full md:w-12/24">
        <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ selectFieldError }}</code>
        </pre>
      </div>
    </div>

  </div>
</div>
