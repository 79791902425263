import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SeoItem } from './seo-item.model';
import { environment } from '../../../../environments/environment';
import { RouteName } from '../../routes';
import { Resource } from '../../resource/resource.model';

@Injectable({
  providedIn: 'root'
})
export class SeoRobotsService {

  private currentRoute: RouteName = null;
  private currentObject: Resource<any> = null;
  private seoItem: SeoItem = null;

  constructor(
    private metaTagService: Meta
  ) {
  }

  setCurrentObject(currentObject): void {
    this.currentObject = currentObject;
  }

  setCurrentRoute(routeName: RouteName): void {
    this.currentRoute = routeName;
  }

  setSeoItem(seoItem: SeoItem): void {
    this.seoItem = seoItem;
  }

  reset(): void {
    this.metaTagService.removeTag('name="robots"');
  }

  refresh(seoItem: SeoItem): void {

    let index = seoItem.index ? 'index' : 'noindex';
    let follow = seoItem.follow ? 'follow' : 'nofollow';

    if (!environment.production) {
      index = 'noindex';
      follow = 'nofollow';
    }

    this.metaTagService.updateTag({
      name: 'robots',
      content: index + ',' + follow
    });
  }
}
