import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class Attachment extends Resource<Request> {
  id: number;
  filename: string;
  original_filename: string;
  title: string;
  type: string;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AttachmentAdapter extends BaseAdapter<Attachment> {
  constructor() {
    super(Attachment);
  }
}
