import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { CalculatorInterestRate, CalculatorInterestRateAdapter } from './calculator-interest-rate.model';

export class Calculator extends Resource<Request> {
  id: number;
  interest_rates: CalculatorInterestRate[];
  max_amount: number;
  max_grace_period: number;
  max_repayment_period: number;
  min_amount: number;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CalculatorAdapter extends BaseAdapter<Calculator> {

  constructor(
    private calculatorInterestRateAdapter: CalculatorInterestRateAdapter
  ) {
    super(Calculator);
  }

  adapt(data: any): Calculator {
    const item = super.adapt(data);

    if (item.interest_rates) {
      item.interest_rates = this.calculatorInterestRateAdapter.adaptArray(item.interest_rates);
    }

    return item;
  }
}
