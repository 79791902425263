<div class="w-full relative flex flex-row md:flex-col group hover:cursor-pointer">
  <img *ngIf="slotable.image"
       class="relative w-[106px] h-full md:w-full aspect-[4/3] object-cover object-center"
       width="272"
       height="204"
       srcset="{{ slotable.image?.i_106x81 }} 106w, {{ slotable.image?.thumbnail }} 272w"
       sizes="(max-width: 768px) 106px, (min-width: 768px) 272px"
       alt="{{ slotable.title }}"
       title="{{ slotable.title }}"
       [src]="slotable.image?.thumbnail"
       loading="lazy">
  <a [appFlLink]="slotable.url" title="{{ slotable.title }}" class="absolute inset-0"></a>

  <div class="md:pt-4 px-2 pb-4 md:pb-0 flex flex-1 flex-col">
    <span class="text-neutral-800 text-12 mb-2" *ngIf="slotable.article_type_label !== 'biography'">
      {{ slotable.publishedAtDate | date: 'dd.MM.yyyy.' }}
    </span>
    <h3
      class="text-neutral-900 group-hover:text-azure-200 group-hover:underline transition-all ease-in-out text-18 md:text-16 lg:text-22 font-400">
      {{ slotable.title }}
    </h3>
    <h4 class="text-16 mt-2 text-neutral-800"
        *ngIf="slotable.article_type_label === 'biography' && slotable.intro_content"
        [ngClass]="{'font-700': !slotable.article_type_label === 'biography'}"
    >{{ slotable.intro_content }}</h4>
  </div>
</div>

