import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { LoanTranslationAdapter } from './loan-translation.model';
import { Tag, TagAdapter } from '../../tags/models/tags.model';
import { LoanCategory, LoanCategoryAdapter } from './loan-category.model';
import { Adapter } from '../../../Library/adapter/adapter';
import { Document, DocumentAdapter } from '../../document/models/document.model';
import { DocumentList, DocumentListAdapter } from '../../document/models/document-list.model';
import { LoanHighlightedItem, LoanHighlightedItemAdapter } from './loan-highlighted-item.model';
import { DateTime } from 'luxon';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';
import { HasSeoItem } from 'src/app/Library/services/seo/has-seo-item';
import { SeoItem } from '../../../Library/services/seo/seo-item.model';
import Helpers from '../../../Library/helpers';
import { BaseAdapter } from 'src/app/Library/adapter/adapter.model';
import { environment } from '../../../../environments/environment';

export class Loan extends Resource<Request> implements HasSeoItem {
  id: number;
  title: string;
  description: string;
  slug: string;
  url: string;
  locale: string;
  headline: string;
  subtitle: string;
  intro_content: string;
  show_table_of_content: number;
  is_adapted: boolean;
  is_featured: boolean;
  main_attribute_key: string;
  main_attribute_value: string;
  loan_category_id: number;
  loan_category: LoanCategory;
  tags: Tag[];
  documents: Document[];
  document_lists: DocumentList[];
  related: Loan[];
  highlighted_items: LoanHighlightedItem[];
  toc_items: {
    id: string,
    level: number,
    text: string,
    indent?: number
  }[];
  public published_at?: string;
  public publishedAtDate?: Date;
  public publishedAtSchemaDate?: string;
  public submit_deadline: string;
  public submitDeadlineDate: Date;
  public availability: string;

  // SEO
  seo_image_id?: number;
  seo_image_url?: string;
  seo_image?: Media;
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;
  seo_is_auto: boolean;

  seo_fb_image_id?: number;
  seo_fb_image?: Media;
  seo_fb_image_url?: string;
  seo_fb_title?: string;
  seo_fb_description?: string;
  seo_fb_keywords?: string;
  seo_fb_is_auto: boolean;

  seo_tw_image_id?: number;
  seo_tw_image?: Media;
  seo_tw_image_url?: string;
  seo_tw_title?: string;
  seo_tw_description?: string;
  seo_tw_keywords?: string;
  seo_tw_is_auto: boolean;

  // SEO END

  public constructor(data?: Partial<Loan>) {
    super(data);
  }

  getUrl(locale?: string): string {
    if (!locale) {
      locale = this.locale;
    }
    switch (locale) {
      case 'en':
        return environment.websiteUrl + '/loan/' + this.slug;
      default:
        return environment.websiteUrl + '/kreditna-linija/' + this.slug;
    }
  }

  getSeoItem(): SeoItem {

    const title = this.seo_title ? this.seo_title : this.title;

    let description = this.seo_description;
    if (!description && this.description) {
      description = this.description.replace(/<\/?[^>]+(>|$)/g, '').substr(0, 160);
    }

    const seoItem = new SeoItem({
      title,
      description,
      url: this.getUrl(),
      seo_fb_title: this.seo_fb_title ? this.seo_fb_title : title,
      seo_fb_description: this.seo_fb_description ? this.seo_fb_description : description,
      seo_fb_type: 'website',
      seo_tw_title: this.seo_tw_title ? this.seo_tw_title : title,
      seo_tw_type: 'website',
    });
    return Helpers.attachSeoImages(this, seoItem);
  }

}

@Injectable({
  providedIn: 'root'
})
export class LoanAdapter extends BaseAdapter<Loan> {

  constructor(
    private mediaAdapter: MediaAdapter,
    private loanTranslationAdapter: LoanTranslationAdapter,
    private tagAdapter: TagAdapter,
    private documentAdapter: DocumentAdapter,
    private loanAdapter: LoanAdapter,
    private loanCategoryAdapter: LoanCategoryAdapter,
    private documentListAdapter: DocumentListAdapter,
    private highlightedItemAdapter: LoanHighlightedItemAdapter
  ) {
    super(Loan);
  }

  adapt(data: any): Loan {
    const item = super.adapt(data);

    if (item.is_adapted) {
      return item;
    }

    if (item.locale === 'me') {
      item.url = '/kreditna-linija/' + item.slug;
    } else {
      item.url = '/en/loan/' + item.slug;
    }

    if (item.tags) {
      item.tags = this.tagAdapter.adaptArray(item.tags);
    }

    if (item.related) {
      item.related = this.adaptArray(item.related);
    }

    if (item.documents) {
      item.documents = this.documentAdapter.adaptArray(item.documents);
    }

    if (item.document_lists) {
      item.document_lists = this.documentListAdapter.adaptArray(item.document_lists);
    }

    if (item.highlighted_items) {
      item.highlighted_items = this.highlightedItemAdapter.adaptArray(item.highlighted_items);
    }

    if (item.seo_image) {
      item.seo_image = this.mediaAdapter.adapt(item.seo_image);
    }

    if (item.seo_fb_image) {
      item.seo_fb_image = this.mediaAdapter.adapt(item.seo_fb_image);
    }

    if (item.seo_tw_image) {
      item.seo_tw_image = this.mediaAdapter.adapt(item.seo_tw_image);
    }

    if (item.description) {
      item.toc_items = [];
      const regexp = /<h([1-6]) id="([a-zA-Z0-9]+)".*?>(.+?\n?)<\/h[1-6]>/g;
      let match = regexp.exec(item.description);
      while (match != null) {
        const toc_item = {
          id: match[2],
          text: match[3],
          level: parseInt(match[1])
        };
        item.toc_items.push(toc_item);
        match = regexp.exec(item.description);
      }
    }

    if (item.published_at) {
      item.publishedAtDate = DateTime.fromSQL(item.published_at, {zone: 'UTC'}).toJSDate();
      item.publishedAtSchemaDate = DateTime.fromSQL(item.published_at, {zone: 'UTC'}).toFormat('y-LL-dd');
    }

    if (item.submit_deadline) {
      item.submitDeadlineDate = DateTime.fromSQL(item.submit_deadline, {zone: 'UTC'}).toJSDate();
    }

    item.is_adapted = true;

    return item;
  }

  adaptArray(items: any): Loan[] {
    items = (items.data.data) ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({data: item});
    });
  }

}
