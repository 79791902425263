<section class="relative w-full my-16 overflow-hidden" style="color: var(--page-accent);">
  <header *ngIf="!contentModule.name_hidden" class="w-full px-4 md:px-0 md:container block w-full mb-12">
    <h1 class="text-28 md:text-30 font-700 text-neutral-900 font-bitter">
      {{ contentModule.name }}
    </h1>
  </header>

  <div *ngIf="!isMobile"
       class="w-full !pl-4 md:!pl-0 md:container flex flex-row overflow-hidden md:overflow-visible pb-4 md:grid md:gap-0 md:grid-cols-24 pb-4">
      <div class="pr-4 pb-4 md:col-span-8 lg:col-span-6" style="flex-grow: 1" *ngFor="let slotable of contentModule.slotables">
        <app-slot-a60 [slotable]="slotable"></app-slot-a60>
      </div>
  </div>

  <div *ngIf="isMobile"
       class="w-full !pl-4 md:!pl-0 md:container flex flex-row overflow-hidden md:overflow-visible pb-4 swiper swiper-module-a62 !z-10 pb-4">
    <div class="swiper-wrapper md:!grid md:gap-0 md:grid-cols-24">
      <div class="swiper-slide pr-4 pb-4 h-full pr-4 pb-4 md:!col-span-8 lg:!col-span-6" style="flex-grow: 1" *ngFor="let slotable of contentModule.slotables">
        <app-slot-a60 [slotable]="slotable"></app-slot-a60>
      </div>
    </div>
  </div>
</section>
