import { Component, Inject, Input, OnChanges, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { LocaleService, RouteTranslation } from '../../../Library/services/locale.service';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit, OnChanges {
  @Input() headerMenu: MenuItem[];

  routeTranslations: RouteTranslation;
  locale: string;
  searchRoute: string = `${routesTrans[currentLocale].search}`;
  menuVisible: boolean = false;
  submenuVisible: boolean[];

  constructor(
    private renderer: Renderer2,
    private localeService: LocaleService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) {
    this.locale = this.localeService.getLocale();
    this.localeService.currentRouteTranslations$.subscribe((routeTranslations: RouteTranslation) => {
      this.routeTranslations = routeTranslations;
    });

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.menuVisible = false;
      }
    });
  }

  ngOnChanges(): void {
    if (this.headerMenu) {
      this.submenuVisible = [];
      for (const item of this.headerMenu) {
        this.submenuVisible.push(false);
      }
    }
  }

  ngOnInit(): void {
  }

  toggleMenu(): void {
    this.menuVisible = !this.menuVisible;
    if (this.menuVisible) {
      this.renderer.addClass(this.document.body, 'overflow-hidden');
    } else {
      this.renderer.removeClass(this.document.body, 'overflow-hidden');
      for (let i = 0; i < this.submenuVisible.length; i++) {
        this.submenuVisible[i] = false;
      }
    }
  }

  onMenuItemClick(index: number, link: string): void {
    if (this.headerMenu[index].items?.length > 0) {
      this.submenuVisible[index] = true;
    } else {
      this.redirect(link);
    }
  }

  redirect(link: string): void {
    if (link) {
      this.toggleMenu();
      window.location.href = link;
    }
  }

  redirectToHome(locale: string): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    location.href = environment.websiteUrl + '/' + locale;
  }

  chooseLanguage(locale: string): void {
    this.redirectToHome(locale);
  }

  closeSubmenu(index: number): void {
    this.submenuVisible[index] = false;
  }
}
