import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { Adapter } from '../../../Library/adapter/adapter';
import { LoanHighlightedItemAttribute, LoanHighlightedItemAttributeAdapter } from './loan-highlighted-item-attribute.model';
import { Calculator, CalculatorAdapter } from '../../calculator/models/calculator.model';
import { DomSanitizer } from '@angular/platform-browser';

export class LoanHighlightedItem extends Resource<Request> {

  id?: number;
  is_adapted?: boolean;
  has_calculator?: boolean;
  attributes: LoanHighlightedItemAttribute[];
  calculator: Calculator;
  headline: string;
  description: string;
  disclaimer: string;

  public constructor(data?: Partial<LoanHighlightedItem>) {
    super(data);
  }

}

@Injectable({
  providedIn: 'root'
})
export class LoanHighlightedItemAdapter implements Adapter<LoanHighlightedItem> {

  constructor(
    private loanHighlightedItemAttributeAdapter: LoanHighlightedItemAttributeAdapter,
    private calculatorAdapter: CalculatorAdapter,
  ) {
  }

  adapt(data: any): LoanHighlightedItem {
    const item = data.data;

    if (item.is_adapted) {
      return item;
    }

    if (item.attributes) {
      item.attributes = this.loanHighlightedItemAttributeAdapter.adaptArray(item.attributes);
    }

    if (item.calculator) {
      item.calculator = this.calculatorAdapter.adapt(item.calculator);
    }

    item.is_adapted = true;

    return item;
  }

  adaptArray(items: any): LoanHighlightedItem[] {
    items = (items.data.data) ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({data: item});
    });
  }

}
