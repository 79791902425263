import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { Adapter } from '../../../Library/adapter/adapter';

export class DocumentListItem extends Resource<Request> {

  id?: number;
  description: string;
  is_adapted?: boolean;

  public constructor(data?: Partial<DocumentListItem>) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DocumentListItemAdapter implements Adapter<DocumentListItem> {

  constructor() {
  }

  adapt(data: any): DocumentListItem {
    const item = data.data;

    if (item.is_adapted) {
      return item;
    }
    item.is_adapted = true;

    return item;
  }

  adaptArray(items: any): DocumentListItem[] {
    items = (items.data) ? items.data : items;
    return items.map((item) => {
      return this.adapt({data: item});
    });
  }

}
