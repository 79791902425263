import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { MenuAdapter } from '../../menu/models/menu.model';
import { Loan, LoanAdapter } from '../../loan/models/loan.model';

export class Detail extends Resource<Request> {

  public additional: any;
  public data: any;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class DetailAdapter extends BaseAdapter<Detail> {

  constructor(
    private menuAdapter: MenuAdapter,
    private loanAdapter: LoanAdapter
  ) {
    super(Detail);
  }

  adapt(data: any): Detail {
    const item = new Detail(data.data);

    if (item.additional) {
      if (item.additional.menus) {
        if (item.additional.menus.main) {
          item.additional.menus.main = this.menuAdapter.adapt(item.additional.menus.main);
        }
        if (item.additional.menus.footer) {
          item.additional.menus.footer = this.menuAdapter.adapt(item.additional.menus.footer);
        }
        if (item.additional.featuredLoans) {
          item.additional.featuredLoans = this.adaptLoansArray(item.additional.featuredLoans);
        }
      }
    }

    return item;
  }

  adaptLoansArray(items: any): Detail[] {
    items = (items.data) ? items.data : items;
    return items.map((item) => {
      return this.loanAdapter.adapt({data: item});
    });
  }

}
