import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MediaConfigurationService {

  private config = {
    mediaInit: {
      sizes: {
        article: [
          {name: 'i_1920x1440', width: 1920, height: 1440},
          {name: 'i_904x678', width: 904, height: 678},
          {name: 'i_390x292', width: 390, height: 292},
          {name: 'i_272x204', width: 272, height: 204},
          {name: 'i_106x81', width: 106, height: 81},
          {name: 'i_1200x675', width: 1200, height: 675},
          {name: 'i_1200x630', width: 1200, height: 630},
          {name: 'thumbnail', width: 390, height: 292},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
        module: [
          {name: 'i_780x1170', width: 780, height: 1170},
          {name: 'i_390x585', width: 390, height: 585},
          {name: 'i_904x678', width: 904, height: 678},
          {name: 'i_390x292', width: 390, height: 292},
          {name: 'i_1920x1440', width: 1920, height: 1440},
          {name: 'i_1920x480', width: 1920, height: 480},
          {name: 'thumbnail', width: 390, height: 292},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
        link: [
          {name: 'i_780x1170', width: 780, height: 1170},
          {name: 'i_390x585', width: 390, height: 585},
          {name: 'i_904x678', width: 904, height: 678},
          {name: 'i_1920x480', width: 1920, height: 480},
          {name: 'thumbnail', width: 390, height: 292},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ]
      }
    }
  };

  public getConfig(name: string): any {
    if (!name || (name && !this.config[name])) {
      return null;
    }

    return this.config[name];
  }
}
