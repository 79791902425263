import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { Adapter } from '../../../Library/adapter/adapter';

export class LoanHighlightedItemAttribute extends Resource<Request> {

  id?: number;
  key?: string;
  value?: string;
  tooltip?: string;
  help_text?: string;
  is_highlighted?: boolean;
  is_primary?: boolean;
  is_adapted?: boolean;

  public constructor(data?: Partial<LoanHighlightedItemAttribute>) {
    super(data);
  }

}

@Injectable({
  providedIn: 'root'
})
export class LoanHighlightedItemAttributeAdapter implements Adapter<LoanHighlightedItemAttribute> {

  constructor() {
  }

  adapt(data: any): LoanHighlightedItemAttribute {
    const item = data.data;

    if (item.is_adapted) {
      return item;
    }
    item.is_adapted = true;

    return item;
  }

  adaptArray(items: any): LoanHighlightedItemAttribute[] {
    items = (items.data.data) ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({data: item});
    });
  }

}
