<section class="relative w-full py-16 overflow-hidden"
         style="color: var(--page-accent);">
  <div class="bg-gradient-to-r from-[#24C7F8] to-[#0070B8] absolute inset-x-0 h-[340px] top-0 z-10"></div>
  <header class="w-full px-4 md:px-0 md:container block w-full flex flex-row items-center justify-between mb-12 relative z-20">

    <h1 class="flex-1 text-28 md:text-30 font-700 text-neutral-white font-bitter" *ngIf="!contentModule.name_hidden" >
      {{ contentModule.name }}
    </h1>

    <div class="flex-row items-center space-x-2 hidden md:flex z-20 relative">
      <button class="w-10 h-10 text-neutral-400 hover:text-neutral-white hover:cursor-pointer transition-colors ease-in-out nav-left-a61">
        <fa-icon class="text-24" [icon]="['fal', 'arrow-left']"></fa-icon>
      </button>
      <button class="w-10 h-10 text-neutral-400 hover:text-neutral-white hover:cursor-pointer transition-colors ease-in-out nav-right-a61">
        <fa-icon class="text-24" [icon]="['fal', 'arrow-right']"></fa-icon>
      </button>
    </div>
  </header>

  <div class="w-full pl-4 md:pl-0 md:container">
    <div class="swiper swiper-module-a61 !z-10">
      <div class="swiper-wrapper flex flex-row">
        <div class="swiper-slide pr-4 pb-4 h-full" style="flex-grow: 1" *ngFor="let slotable of contentModule.slotables">
          <app-slot-a60 [slotable]="slotable"></app-slot-a60>
        </div>
      </div>
    </div>
  </div>
</section>
