<div class="group z-100 fixed top-0 w-full shadow-sm pointer-events-none">
  <div
    class="z-100 w-full transition-all origin-left duration-200 bg-neutral-white group-hover:bg-[#ECECEC] ease-in">
    <div class="container h-full flex items-center justify-end relative">
      <a routerLink="/" class="z-100 pointer-events-auto absolute top-0 left-0 w-4/24 lg:w-3/24 bg-white shadow-sm h-[106px] bg-neutral-white flex items-center justify-center">
        <img class="block" src="assets/img/logos/logo-header-desktop.png" width="112" height="78" title="Početna" i18n-title="@@IRF_Header_Homepage_Link" alt="Investiciono-razvojni fond"/>
      </a>
      <a *ngFor="let headerItem of headerMenu, let i = index"
         title="{{ headerItem.title }}"
         (mouseenter)="onHeaderItemOver(i)"
         (mouseleave)="onHeaderItemLeave(i)"
         (click)="onHeaderItemLeave(i)"
         [href]="headerItem.url"
         class="pointer-events-auto h-full flex items-center px-4 pt-4 pb-5 text-14 font-700 text-neutral-800 uppercase border-t-3 border-transparent transition-colors duration-200 ease-in hover:cursor-pointer"
         [ngClass]="{'bg-neutral-white border-azure-100' : headerItemActive[i]}">
        {{ headerItem.title }}
      </a>
      <a [routerLink]="[searchRoute]"
         title="Pretraga"
         i18n-title="@@IRF_Header_Search_Link"
         class="pointer-events-auto h-[57px] pt-4 pb-5 text-neutral-800 hover:text-azure-100 hover:bg-neutral-white border-t-3 border-transparent hover:border-azure-100 transition-colors duration-200 ease-in flex items-center">
        <div class="w-full h-full border-x border-neutral-400 flex items-center justify-center px-4 text-14">
          <fa-icon [icon]="['fal', 'magnifying-glass']"></fa-icon>
        </div>
      </a>
      <button class="relative h-[57px] pointer-events-auto" (click)="toggleLocaleMenu()">
        <div
          class="h-full flex items-center justify-center border-t-3 pt-4 pb-5 px-4 hover:bg-neutral-white hover:border-azure-100 transition-colors duration-200 ease-in"
          [ngClass]="{'border-azure-100' : localeDropdownVisible, 'border-transparent' : !localeDropdownVisible}">
          <img src="assets/img/logos/flag-{{ locale }}.svg" class="w-4 h-4" width="512" height="512"
               alt="IRF - {{ locale }}"/>
          <fa-icon *ngIf="!localeDropdownVisible" [icon]="['fas', 'chevron-down']" class="ml-2 text-10"></fa-icon>
          <fa-icon *ngIf="localeDropdownVisible" [icon]="['fas', 'chevron-up']" class="ml-2 text-10"></fa-icon>
        </div>
        <div
          class="absolute left-0 right-0 p-1 bg-neutral-white shadow-sm border border-neutral-100 text-14 transition-all duration-200 flex flex-col gap-1 items-center"
          [ngClass]="{'max-h-12' : localeDropdownVisible, 'max-h-0 opacity-0' : !localeDropdownVisible}">
          <a *ngIf="routeTranslations.me" [href]="routeTranslations.me"
             title="Crnogorski"
             i18n-title="@@IRF_Header_Montenegrin_Link"
             class="hover:text-azure-300"
             [ngClass]="{'text-azure-100' : locale === 'me'}">
            MNE
          </a>
          <button *ngIf="!routeTranslations.me" (click)="chooseLanguage('me')"
             title="Crnogorski"
             i18n-title="@@IRF_Header_Montenegrin_Link"
             class="hover:text-azure-300"
             [ngClass]="{'text-azure-100' : locale === 'me'}">
            MNE
          </button>
          <a *ngIf="routeTranslations.en" [href]="routeTranslations.en"
             title="Engleski"
             i18n-title="@@IRF_Header_English_Link"
             class="hover:text-azure-300"
             [ngClass]="{'text-azure-100' : locale === 'en'}">
            ENG
          </a>
          <button *ngIf="!routeTranslations.en" (click)="chooseLanguage('en')"
             title="Engleski"
             i18n-title="@@IRF_Header_English_Link"
             class="hover:text-azure-300"
             [ngClass]="{'text-azure-100' : locale === 'en'}">
            ENG
          </button>
        </div>
      </button>
    </div>
  </div>

  <div *ngFor="let headerItem of headerMenu, let i = index"
       class="pointer-events-auto z-50 absolute left-0 right-0 scroll-top-position">
    <div *ngIf="headerItem.items?.length > 0" class="w-full"
         [ngClass]="{'min-h-0': !headerItemActive[i], 'min-h-[334px]' : headerItemActive[i] }">
      <div class="w-full bg-neutral-400 transition-[transform,opacity] origin-top duration-200 ease-out"
           [ngClass]="{'opacity-0 scale-y-0' : !headerItemActive[i], 'opacity-100 scale-y-100': headerItemActive[i]}"
           (mouseenter)="onHeaderItemOver(i)"
           (mouseleave)="onHeaderItemLeave(i)">
        <div class="dropdown-menu transition-all duration-100 ease-in">
          <div class="container relative z-10 flex flex-row">
            <div class="w-5/24 ml-4/24 pt-14 pr-1/24">
              <span class="text-24 leading-125 font-700 text-neutral-900">
                {{ headerItem.title }}
              </span>
            </div>
            <div *ngFor="let childItem of headerItem.items" class="w-5/24 pt-14 pb-10 pr-6">
              <span class="text-18 text-neutral-900 font-700 leading-150">
                {{ childItem.title }}
              </span>
              <div class="mt-5 flex flex-col gap-3">
                <a *ngFor="let grandChildItem of childItem.items"
                   title="{{ grandChildItem.title }}"
                   [href]="grandChildItem.url"
                   (click)="onHeaderItemLeave(i)"
                   class="text-14 leading-125 text-neutral-900 hover:text-azure-100 hover:cursor-pointer transition-text duration-100">
                  {{ grandChildItem.title }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="w-full h-[100vh] z-90 fixed top-0 bg-neutral-white/70 backdrop-blur-[12px] pointer-events-none transition-opacity duration-300"
     [ngClass]="{'opacity-0': !anyHeaderItemActive, 'opacity-100': anyHeaderItemActive}">
</div>
