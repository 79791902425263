import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ds-icons',
  templateUrl: './ds-icons.component.html',
  styleUrls: ['./ds-icons.component.scss']
})
export class DsIconsComponent implements OnInit {
  icon1: string;
  icon2: string;
  icon3: string;
  icon4: string;
  icon5: string;
  icon6: string;
  icon7: string;
  icon8: string;
  icon9: string;
  icon10: string;
  icon11: string;
  icon12: string;
  icon13: string;
  icon14: string;
  icon15: string;
  icon16: string;
  icon17: string;
  icon18: string;
  icon19: string;
  icon20: string;
  icon21: string;
  icon22: string;
  icon23: string;
  icon24: string;
  icon25: string;
  icon26: string;
  icon27: string;
  icon28: string;
  icon29: string;
  icon30: string;
  icon31: string;
  icon32: string;
  icon33: string;
  icon34: string;
  icon35: string;
  icon36: string;
  icon37: string;
  icon38: string;
  icon39: string;
  icon40: string;
  icon41: string;
  icon42: string;
  icon43: string;
  icon44: string;
  icon45: string;
  icon46: string;

  constructor() {
  }

  ngOnInit(): void {
    this.icon1 = `<fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>`;
    this.icon2 = `<fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>`;
    this.icon3 = `<fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>`;
    this.icon4 = `<fa-icon [icon]="['fas', 'chevron-up']"></fa-icon>`;
    this.icon5 = `<fa-icon [icon]="['fas', 'circle-info']"></fa-icon>`;
    this.icon6 = `<fa-icon [icon]="['fas', 'envelope']"></fa-icon>`;
    this.icon7 = `<fa-icon [icon]="['fas', 'hands-holding-circle']"></fa-icon>`;
    this.icon8 = `<fa-icon [icon]="['fas', 'house-building']"></fa-icon>`;
    this.icon9 = `<fa-icon [icon]="['fas', 'person-carry-box']"></fa-icon>`;
    this.icon10 = `<fa-icon [icon]="['fas', 'print']"></fa-icon>`;
    this.icon11 = `<fa-icon [icon]="['fas', 'rocket-launch']"></fa-icon>`;
    this.icon12 = `<fa-icon [icon]="['fas', 'square-check']"></fa-icon>`;
    this.icon13 = `<fa-icon [icon]="['fas', 'tractor']"></fa-icon>`;
    this.icon14 = `<fa-icon [icon]="['fas', 'umbrella-beach']"></fa-icon>`;
    this.icon15 = `<fa-icon [icon]="['fas', 'user']"></fa-icon>`;
    this.icon16 = `<fa-icon [icon]="['fal', 'location-dot']"></fa-icon>`;
    this.icon17 = `<fa-icon [icon]="['fal', 'phone']"></fa-icon>`;
    this.icon18 = `<fa-icon [icon]="['fal', 'user']"></fa-icon>`;
    this.icon19 = `<fa-icon [icon]="['fal', 'envelope']"></fa-icon>`;
    this.icon20 = `<fa-icon [icon]="['fal', 'hands-holding-circle']"></fa-icon>`;
    this.icon21 = `<fa-icon [icon]="['fal', 'rocket-launch']"></fa-icon>`;
    this.icon22 = `<fa-icon [icon]="['fal', 'tractor']"></fa-icon>`;
    this.icon23 = `<fa-icon [icon]="['fal', 'umbrella-beach']"></fa-icon>`;
    this.icon24 = `<fa-icon [icon]="['fal', 'house-building']"></fa-icon>`;
    this.icon25 = `<fa-icon [icon]="['fal', 'circle-info']"></fa-icon>`;
    this.icon26 = `<fa-icon [icon]="['fal', 'language']"></fa-icon>`;
    this.icon27 = `<fa-icon [icon]="['fal', 'circle-minus']"></fa-icon>`;
    this.icon28 = `<fa-icon [icon]="['fal', 'minus']"></fa-icon>`;
    this.icon29 = `<fa-icon [icon]="['fal', 'circle-plus']"></fa-icon>`;
    this.icon30 = `<fa-icon [icon]="['fal', 'magnifying-glass']"></fa-icon>`;
    this.icon31 = `<fa-icon [icon]="['fal', 'arrow-up']"></fa-icon>`;
    this.icon32 = `<fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>`;
    this.icon33 = `<fa-icon [icon]="['fal', 'arrow-down']"></fa-icon>`;
    this.icon34 = `<fa-icon [icon]="['fal', 'arrow-left']"></fa-icon>`;
    this.icon35 = `<fa-icon [icon]="['fal', 'plus']"></fa-icon>`;
    this.icon36 = `<fa-icon [icon]="['fal', 'circle-question']"></fa-icon>`;
    this.icon37 = `<fa-icon [icon]="['fal', 'xmark']"></fa-icon>`;
    this.icon38 = `<fa-icon [icon]="['fal', 'lightbulb-on']"></fa-icon>`;
    this.icon39 = `<fa-icon [icon]="['fal', 'arrows-down-to-people']"></fa-icon>`;
    this.icon40 = `<fa-icon [icon]="['fal', 'handshake']"></fa-icon>`;
    this.icon41 = `<fa-icon [icon]="['fal', 'chart-line-up']"></fa-icon>`;
    this.icon42 = `<fa-icon [icon]="['fal', 'user-tie-hair-long']"></fa-icon>`;
    this.icon43 = `<fa-icon [icon]="['fal', 'user-tag']"></fa-icon>`;
    this.icon44 = `<fa-icon [icon]="['fal', 'bars']"></fa-icon>`;
    this.icon45 = `<fa-icon [icon]="['fal', 'xmark-large']"></fa-icon>`;
    this.icon46 = `<fa-icon [icon]="['far', 'check']"></fa-icon>`;
  }
}
