import { Component, Input, OnInit } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a32',
  templateUrl: './slot-a32.component.html',
  styleUrls: ['./slot-a32.component.scss']
})
export class SlotA32Component implements OnInit {

  @Input() slotable: Slot;
  faArrowRight = faArrowRight;

  constructor() { }

  ngOnInit(): void {
  }

}
