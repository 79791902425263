<div class="block w-full h-full rounded-8 relative overflow-hidden bg-neutral-black/30 hover:bg-neutral-black/40
  backdrop-blur transition-colors">
  <div class="w-full h-full border-l-8 border-azure-100 pt-4 pb-6 px-6">
    <span class="text-neutral-white text-18 md:text-16 lg:text-18 leading-155">
      {{ slotable.title }}
    </span>
  </div>
  <a *ngIf="slotType !== 'link'" class="absolute inset-0"
     [routerLink]="slotable.url">
  </a>
  <a *ngIf="slotType === 'link'" class="absolute inset-0"
     href="{{ slotable.url }}" target="_blank">
  </a>
</div>
