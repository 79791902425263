<div class="w-full relative h-full flex flex-col justify-start p-4 md:pr-0">
  <fa-icon class="text-30 md:text-22 lg:text-30 text-neutral-800 mb-6 lg:mb-8" [icon]="['fal', slotable.icon ]"></fa-icon>
  <div class="flex flex-col flex-1 space-y-1 slot-a80-border pb-4 md:pb-0 pr-4">
    <h3 class="text-neutral-900 text-18 md:text-16 lg:text-22 font-700 line-clamp-2">
      {{ slotable.title }}
    </h3>
    <p class="text-neutral-900 text-18 md:text-16 lg:text-14 font-400 line-clamp-3">
      {{ cleanDescription }}
    </p>
  </div>
</div>
