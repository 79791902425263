import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-system',
  templateUrl: './design-system.component.html'
})

export class DesignSystemComponent implements  OnInit{
  sections = [
    { value: 0, label: 'All components' },
    { value: 1, label: 'Buttons' },
    { value: 2, label: 'Input Fields' },
    { value: 3, label: 'Icons' }
  ];

  activeSection: number = 2;

  constructor() {
  }

  ngOnInit(): void {
  }

  selectSection(section): void {
    this.activeSection = section;
  }

}
