import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LocaleService, RouteTranslation } from '../../../Library/services/locale.service';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';
import { GlobalService } from '../../../Library/services/global.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { MenuService } from '../../../@modules/menu/services/menu.service';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderDesktopComponent implements OnInit, OnDestroy, OnChanges {
  @Input() headerMenu: MenuItem[];

  routeTranslations: RouteTranslation;
  locale: string;

  searchRoute: string = `${routesTrans[currentLocale].search}`;
  ngUnsubscribe = new Subject();

  headerItemActive: boolean[];
  anyHeaderItemActive: boolean = false;

  localeDropdownVisible: boolean = false;

  constructor(
    private localeService: LocaleService,
    private menuService: MenuService,
    private globalService: GlobalService,
    private detailService: DetailService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) public localeCode,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.locale = this.localeService.getLocale();
    this.localeService.currentRouteTranslations$.subscribe((routeTranslations: RouteTranslation) => {
      this.routeTranslations = routeTranslations;
    });
  }

  ngOnChanges(): void {
    if (this.headerMenu) {
      this.headerItemActive = [];
      for (const item of this.headerMenu) {
        this.headerItemActive.push(false);
      }
    }
  }

  ngOnInit(): void {
    this.setEscapeListener();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onHeaderItemOver(index: number): void {
    this.headerItemActive[index] = true;
    this.anyHeaderItemActive = true;
  }

  onHeaderItemLeave(index: number): void {
    this.headerItemActive[index] = false;
    this.anyHeaderItemActive = false;
  }

  toggleLocaleMenu(): void {
    this.localeDropdownVisible = !this.localeDropdownVisible;
  }

  setEscapeListener(): void {
    if (isPlatformBrowser(this.platformId)) {
      const escapeListener = this.renderer.listen(document, 'keydown.escape', () => {
        this.localeDropdownVisible = false;
      });
    }
  }

  redirectToHome(locale: string): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    location.href = environment.websiteUrl + '/' + locale;
  }

  chooseLanguage(locale: string): void {
    this.redirectToHome(locale);
  }

}
