<section class="relative w-full my-16">
  <header *ngIf="!contentModule.name_hidden" class="container block w-full mt-6 mb-12">
    <h1 class="text-28 md:text-30 font-700 text-neutral-900 font-bitter">
      {{ contentModule.name }}
    </h1>
  </header>
  <div class="container grid grid-cols-24 gap-4 grid-flow-row-dense">
    <div *ngFor="let slotable of contentModule.slotables"
         class="flex col-span-12 md:col-span-8 bg-neutral-white rounded-16 item-center justify-center px-8 py-10 md:px-12 md:py-8 md:max-h-[204px] relative border border-neutral-100 transition-all ease-in-out"
         [ngClass]="{ 'hover:border-neutral-400' : slotable.url }">
      <a *ngIf="slotable.url" [appFlLink]="slotable.url" title="{{ slotable.title }}" class="absolute inset-0 cursor-pointer"></a>
      <img *ngIf="slotable.image" style="aspect-ratio: 3/2;" class="object-contain"
           [src]="slotable.image.thumbnail"
           [alt]="slotable.title">
    </div>
  </div>
</section>
