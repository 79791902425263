<a class="w-full relative h-full flex flex-col justify-between group hover:cursor-pointer shadow-sm rounded-8 p-4 space-y-8 bg-neutral-white hover:border-neutral-400 border border-neutral-100 transition-all ease-in-out"
   [appFlLink]="slotable.url" title="{{ slotable.title }}">
  <div class="flex flex-col">
    <span class="font-700 text-12 mb-2 uppercase"
      [ngStyle]="{ 'color' : slotable.loan_category_color }">
      {{ slotable.loan_category_title }}
    </span>
    <h3 class="text-neutral-900 group-hover:text-azure-200 group-hover:underline transition-all ease-in-out text-18 md:text-16 lg:text-18 font-400 line-clamp-3">
      {{ slotable.title }}
    </h3>
  </div>
  <div class="border-l-4 pl-3 flex flex-row items-center justify-between"
      *ngIf="slotable.main_attribute_key || slotable.main_attribute_value"
      [ngStyle]="{ 'border-color' : slotable.loan_category_color }">
    <span class="flex flex-col flex-1 space-y-1">
      <span class="text-12 font-400 text-neutral-800" *ngIf="slotable.main_attribute_key">{{ slotable.main_attribute_key }}</span>
      <span class="text-16 font-700 text-neutral-900" *ngIf="slotable.main_attribute_value">{{ slotable.main_attribute_value }}</span>
    </span>
    <fa-icon [icon]="['fas', 'chevron-right']" class="text-neutral-800 group-hover:text-category-300"></fa-icon>
  </div>
</a>
