<section class="relative w-full my-16 overflow-hidden"
         style="color: var(--page-accent);">
    <header class="w-full px-4 md:px-0 md:container block w-full flex flex-row items-center justify-between mb-12">

      <h1 class="flex-1 text-28 md:text-30 font-700 text-neutral-900 font-bitter" *ngIf="!contentModule.name_hidden" >
        {{ contentModule.name }}
      </h1>

      <div class="flex-row items-center space-x-2 hidden md:flex">
        <button class="w-10 h-10 text-neutral-400 hover:text-neutral-900 hover:cursor-pointer transition-colors ease-in-out nav-left-a60">
          <fa-icon class="text-24" [icon]="['fal', 'arrow-left']"></fa-icon>
        </button>
        <button class="w-10 h-10 text-neutral-400 hover:text-neutral-900 hover:cursor-pointer transition-colors ease-in-out nav-right-a60">
          <fa-icon class="text-24" [icon]="['fal', 'arrow-right']"></fa-icon>
        </button>
      </div>
    </header>

    <div class="w-full pl-4 md:pl-0 md:container">
      <div class="swiper swiper-module-a60">
        <div class="swiper-wrapper flex flex-row">
          <div class="swiper-slide pr-4 pb-4 h-full" style="flex-grow: 1" *ngFor="let slotable of contentModule.slotables">
            <app-slot-a60 [slotable]="slotable"></app-slot-a60>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="w-full pl-4 md:pl-0 md:container keen-slider__track keen-slider flex flex-row overflow-hidden" #moduleA060Slider>-->
<!--      <div *ngFor="let slotable of contentModule.slotables"-->
<!--           class="keen-slider__slide pr-4 pb-4">-->
<!--        <app-slot-a60 [slotable]="slotable"></app-slot-a60>-->
<!--      </div>-->
<!--    </div>-->
</section>
