<section class="w-full my-14">
  <div class="w-full md:container relative">
    <div class="flex flex-col md:flex-row">
      <div class="relative w-full">
        <div class="relative w-full md:ml-3/24 md:w-21/24 md:rounded-r-16 overflow-hidden">
          <img class="w-full aspect-video" src="{{ contentModule.image?.twitter }}">
          <div class="absolute inset-0 hidden md:flex md:items-center">
            <div class="w-12/24 h-full bg-gradient-to-l from-transparent"
                 [ngClass]="{'to-neutral-100': !contentModule.is_highlighted, 'to-neutral-white': contentModule.is_highlighted}">
            </div>
          </div>
        </div>

        <div class="static md:absolute md:inset-0 md:flex md:items-center">
          <div class="w-full md:w-15/24 lg:w-10/24 md:px-1/24 py-9 md:py-10 bg-neutral-white md:rounded-16 shadow-sm">
            <div class="container md:px-0">
              <h2 class="pl-4 border-l-8 border-azure-300 text-24 leading-125 text-neutral-900 font-700 font-bitter">
                {{ contentModule.name }}
              </h2>
              <div class="w-full mt-6 pl-6 flex flex-col gap-4">
                <div *ngFor="let slotable of contentModule.slotables">
                  <app-slot-a110 [slotable]="slotable"></app-slot-a110>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
