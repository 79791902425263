<main class="flex-1 relative z-0 focus:outline-none">
  <app-header></app-header>
  <div class="main-content relative mt-[58px]">
    <router-outlet *ngIf="!(is404Subject | async)"></router-outlet>
    <app-not-found *ngIf="is404Subject | async"></app-not-found>
  </div>
  <app-footer></app-footer>
</main>

<div *ngIf="showResponsiveHelper"
     class="w-full bg-category-100 bg-opacity-40 sm:bg-opacity-50 md:bg-opacity-60 lg:bg-opacity-70 xl:bg-opacity-80 px-4 py-6 fixed bottom-0 z-200">
  <div class="w-full flex items-center justify-center text-24">
    <span class="flex sm:hidden flex-col items-center">
      <span>XS View</span>
      <span class="text-18">Viewport < 320px</span>
    </span>
    <span class="hidden sm:flex md:hidden flex-col items-center">
      <span>SM View</span>
      <span class="text-18">320px < Viewport < 768px</span>
    </span>
    <span class="hidden md:flex lg:hidden flex-col items-center">
      <span>MD View</span>
      <span class="text-18">768px < Viewport < 1136px</span>
    </span>
    <span class="hidden lg:flex flex-col items-center">
      <span>LG View</span>
      <span class="text-18">Viewport > 1136px</span>
    </span>
  </div>
</div>
