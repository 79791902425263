import { ChangeDetectionStrategy, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { GlobalService } from '../../../Library/services/global.service';
import { BehaviorSubject } from 'rxjs';
import { SeoService } from '../../../Library/services/seo/seo.service';
import { RouteName } from '../../../Library/routes';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppLayoutComponent implements OnInit {
  public is404Subject: BehaviorSubject<boolean>;
  public showResponsiveHelper: boolean = false;

  isMobileLayout: boolean = null;
  isMenuMobileLayout: boolean = null;
  homeScrollTrigger: number;

  constructor(
    private seoService: SeoService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId,
    globalService: GlobalService
  ) {
    this.is404Subject = globalService.is404;
  }

  ngOnInit(): void {
    const responsiveHelper = this.activatedRoute.snapshot.queryParams;
    if (responsiveHelper.responsive_mode) {
      this.showResponsiveHelper = true;
    }
    this.is404Subject.subscribe(is404 => {
      if (is404) {
        this.seoService.refreshWithDefault();
      }
    });

    if (isPlatformBrowser(this.platformId)) {

      if (window.innerWidth <= 768){
        this.homeScrollTrigger = 200;
      } else{
        this.homeScrollTrigger = 120;
      }


      this.isMenuMobileLayout = window.innerWidth <= 768;
      window.onresize = () => this.isMenuMobileLayout = window.innerWidth <= 768;

      this.isMobileLayout = window.innerWidth <= 1140;
      window.onresize = () => this.isMobileLayout = window.innerWidth <= 1140;
    }

  }
}
