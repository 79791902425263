import { SeoItem } from '../../../Library/services/seo/seo-item.model';
import { RouteName } from '../../../Library/routes';

export const seoRulesDefault = {
  me: new SeoItem({
    title: 'Investiciono razvojni fond Crne Gore - Website',
    description: 'Investiciono razvojni fond Crne Gore - Website description',
    seo_title: 'Investiciono razvojni fond Crne Gore - Website',
    seo_fb_title: 'Investiciono razvojni fond Crne Gore - Website',
    seo_fb_description: 'Investiciono razvojni fond Crne Gore - Website description fb',
    seo_fb_image_url: '/assets/img/seo/irf-seo-me-facebook.jpg',
    seo_fb_type: 'website',
    seo_tw_title: 'Investiciono razvojni fond Crne Gore - Website',
    seo_tw_description: 'Investiciono razvojni fond Crne Gore - Website description tw',
    seo_tw_image_url: '/assets/img/seo/irf-seo-me-twitter.jpg',
    seo_keywords: 'IRF, Centar, Crna Gora',
    url: ''
  }),
  en: new SeoItem({
    title: 'Investiciono razvojni fond Crne Gore - Website',
    description: 'Investiciono razvojni fond Crne Gore - Website description',
    seo_title: 'Investiciono razvojni fond Crne Gore - Website',
    seo_fb_title: 'Investiciono razvojni fond Crne Gore - Website',
    seo_fb_description: 'Investiciono razvojni fond Crne Gore - Website description',
    seo_fb_image_url: '/assets/img/seo/irf-seo-en-facebook.jpg',
    seo_description: 'Investiciono razvojni fond Crne Gore - Website description',
    seo_tw_title: 'Investiciono razvojni fond Crne Gore - Website',
    seo_tw_description: 'Investiciono razvojni fond Crne Gore - Website description',
    seo_tw_image_url: '/assets/img/seo/irf-seo-en-twitter.jpg',
    seo_fb_type: 'website',
    seo_keywords: 'IRF, Center, Montenegro',
    url: ''
  })
};

