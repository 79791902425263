<section class="relative w-full my-16" style="color: var(--page-accent);">
  <header *ngIf="!contentModule.name_hidden"
          class="container grid gap-4 grid-cols-6 md:grid-cols-24">
    <div class="col-span-6 md:col-span-14 md:col-start-6 mb-12 text-center text-neutral-900 space-y-4">
      <h1 class="text-28 md:text-30 font-700 font-bitter">{{ contentModule.name }}</h1>
      <p class="text-18" *ngIf="contentModule.description">{{ contentModule.description }}
      </p>
    </div>
  </header>
  <div class="container grid gap-4 grid-cols-6 md:grid-cols-24 mt-6">
    <div class="col-span-6 md:col-span-14 md:col-start-6">
      <div class="flex flex-row flex-wrap md:space-x-4 justify-center space-y-4 md:space-y-0">
        <div *ngFor="let slotable of contentModule.slotables, let index = index"
          class="w-full md:w-auto">
          <app-slot-a90
            [slotable]="slotable"
            [index]="index"
            [count]="contentModule.slotables.length"
          ></app-slot-a90>
        </div>
      </div>
    </div>
  </div>
</section>
