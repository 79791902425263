import {Injectable} from '@angular/core';
import {Resource} from '../../../Library/resource/resource.model';
import {BaseAdapter} from '../../../Library/adapter/adapter.model';
import {routes, currentLocale} from 'src/app/Library/routes';
import {Params} from '@angular/router';

export class MenuItem extends Resource<Request> {

  public position: number;
  public slug: string;
  public state: string;
  public title: string;
  public type: string;
  public url: string;
  public link: string;
  public query_params: Params;
  public level: number;
  public menuable: any;
  public menuable_type: string;
  public menuable_id: number;

  public items: MenuItem[];

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MenuItemAdapter extends BaseAdapter<MenuItem> {

  constructor() {
    super(MenuItem);
  }

  adapt(data: any): MenuItem {
    const item = super.adapt(data);

    if (item.type === 'custom-url') {
      const splitURL = item.url.split('?');
      item.link = splitURL[0];
      item.query_params = this.getQueryParamsFromURL(splitURL[1]);
    }

    if (item.type === 'placeholder') {
      item.link = '#';
    }

    if (item.type === 'page' && item.menuable) {
      item.link = this.getLinkLocale() + '/' + item.menuable.path;
      item.url = item.link;
    }

    if (item.type === 'general-link') {
      item.link = this.getLinkLocale() + '/' + routes[currentLocale][item.state];
    }

    if (item.items) {
      item.items = this.adaptArray(item.items);
    }

    return item;
  }

  adaptArray(items: any): MenuItem[] {
    const response = [];

    if (items && items.data && items.data.data) {
      items = items.data.data;
    } else if (items && items.data) {
      items = items.data;
    }

    if (items) {
      const publishedItems = items.filter(item => item.status === 'PUBLISHED');

      publishedItems.forEach((item) => {
        response.push(this.adapt(item));
      });
    }

    return response;
  }

  private getLinkLocale(): string {
    return currentLocale === 'me' ? '' : '/' + currentLocale;
  }

  private getQueryParamsFromURL(paramsString: string): any {
    const queryParams: {} = {};

    if (!paramsString) {
      return queryParams;
    }

    const splitParams: string[] = paramsString.split('&');

    splitParams.forEach((queryParam: string) => {
      const keyAndValue: string[] = queryParam.split('=');
      queryParams[keyAndValue[0]] = keyAndValue[1];
    });

    return queryParams;
  }
}
