<div class="text-neutral-white">
  <h2 class="text-30 font-700 mb-4 font-bitter">{{ slotable.title }}</h2>
  <p class="text-16 md:text-18 mb-8" [innerHTML]="slotable.description"></p>
  <a *ngIf="slotType !== 'custom-html'"
    title="{{ slotable.title }}" [appFlLink]="slotable.url"
     class="btn btn__primary--on-dark md:inline-flex"
  >
    <span i18n="@@general_learn_more_btn">Saznajte više</span>
    <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
  </a>
</div>
