<footer class="w-full pt-8 bg-azure-300 text-neutral-white" id="footer">
  <div class="container">
    <div class="pt-8 pb-12 md:py-10 flex flex-col md:flex-row justify-between">
      <div class="md:w-5/24 pr-8/24 md:pr-1/24 flex flex-col justify-between">
        <img class="mb-4" src="assets/img/logos/logo-white.png" width="176" height="32" alt="IRF"/>
        <div *ngIf="!isMobileLayout">
          <span class="text-14 leading-115 mb-4" i18n="@@IRF_Footer_Follow_US">Pratite nas</span>
          <div class="flex flex-row gap-6 flex-wrap">
            <a class="text-24 hover:text-azure-100 hover:cursor-pointer" href="https://www.facebook.com/IRFCG/"
               target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
            </a>
            <a class="text-24 hover:text-azure-100 hover:cursor-pointer"
               href="https://www.instagram.com/investiciono_razvojni_fond"
               target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
            </a>
            <a class="text-24 hover:text-azure-100 hover:cursor-pointer" href="https://me.linkedin.com/company/irfcg"
               target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
            </a>
            <a class="text-24 hover:text-azure-100 hover:cursor-pointer" href="https://twitter.com/irf_cg"
               target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="md:w-6/24 pr-8/24 md:pr-1/24 pt-12 md:pt-0">
        <span *ngFor="let menuItem of footerMenu | slice:0: 1"
              class="text-20 md:text-16 font-700 leading-125">
          {{ menuItem.title }}
        </span>
        <div *ngFor="let menuItem of footerMenu | slice:0: 1"
             class="mt-8 flex flex-col justify-between gap-4">
          <a *ngFor="let childItem of menuItem.items"
             [routerLink]="childItem.type !== 'placeholder' ? childItem.link : null"
             [queryParams]="childItem.query_params"
             class="text-14 hover:text-azure-100 transition-text duration-100 hover:cursor-pointer">
            {{ childItem.title }}
          </a>
        </div>
      </div>
      <div class="md:w-6/24 pr-8/24 md:pr-1/24 pt-12 md:pt-0">
        <span *ngFor="let menuItem of footerMenu | slice:1: 2"
              class="text-20 md:text-16 font-700 leading-125">
          {{ menuItem.title }}
        </span>
        <div *ngFor="let menuItem of footerMenu | slice:1: 2"
             class="mt-8 flex flex-col justify-between gap-4">
          <a *ngFor="let childItem of menuItem.items"
             [routerLink]="childItem.type !== 'placeholder' ? childItem.link : null"
             [queryParams]="childItem.query_params"
             class="text-14 hover:text-azure-100 transition-text duration-100 hover:cursor-pointer">
            {{ childItem.title }}
          </a>
        </div>
      </div>
      <div class="flex flex-col md:w-7/24 pt-12 md:pt-0">
        <div class="flex flex-col mb-8 md:mb-6">
          <span class="mb-4 md:mb-6 text-16 leading-115 font-700">
            <span i18n="@@IRF_full_brand_name">Investiciono-razvojni fond Crne Gore A.D.</span>
          </span>

          <span class="font-400 text-14 mb-4">
            Bulevar Svetog Petra Cetinjskog 126/I, Podgorica
          </span>

          <span class="font-400 mb-2 text-14">
            <span i18n="@@IRF_Footer_call_center">CALL CENTAR - besplatan info broj</span>:
          </span>
          <a href="tel:080012012" class="font-700 text-14 mb-4 text-azure-50 hover:text-azure-200">080 012 012</a>

          <span class="font-400 mb-2 text-14">
            <span i18n="@@IRF_Footer_email_address">e-mail adresa</span>:
          </span>
          <a href="mailto:info@irfcg.me"
             class="font-700 text-14 mb-4 text-azure-50 hover:text-azure-200">info@irfcg.me</a>

          <span class="font-400 mb-2 text-14">
            <span i18n="@@IRF_Footer_service_for_loans">Služba za kredite i garancije</span>:
          </span>
          <a href="tel:+38220416600" class="font-400 text-14 mb-2 text-azure-50 hover:text-azure-200">
            tel: +382 20 41 66 00
          </a>
          <a href="tel:+38220416601" class="font-400 text-14 mb-4 text-azure-50 hover:text-azure-200">
            fax: +382 20 41 66 01
          </a>
          <span class="font-400 mb-2 text-14">
            <span i18n="@@IRF_Footer_BP_office">Kancelarija u Bijelom Polju</span>:
          </span>
          <span class="font-400 text-14 mb-2 text-neutral-white">Ul Slobode br 1</span>
          <a href="tel:+38250431666" class="font-400 text-14 mb-2 text-azure-50 hover:text-azure-200">
            tel: +382 50 43 16 66
          </a>
          <a href="tel:+38250431666" class="font-400 text-14 mb-4 text-azure-50 hover:text-azure-200">
            tel: +382 50 43 16 66
          </a>
        </div>
        <div *ngIf="isMobileLayout">
          <span class="block text-16 leading-125 mb-6" i18n="@@IRF_Footer_Follow_US">Pratite nas</span>
          <div class="flex flex-row space-x-6">
            <a class="text-24" href="https://www.facebook.com/IRFCG/"
               target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
            </a>
            <a class="text-24" href="https://www.instagram.com/investiciono_razvojni_fond"
               target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
            </a>
            <a class="text-24" href="https://me.linkedin.com/company/irfcg"
               target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
            </a>
            <a class="text-24" href="https://twitter.com/irf_cg"
               target="_blank" rel="noopener noreferrer">
              <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-full pt-6 pb-7 border-t border-neutral-white/20 flex flex-col-reverse md:flex-row md:items-center md:justify-between gap-y-4">
      <div class="w-full md:w-9/24 text-12 leading-125 text-neutral-white/60">
        <span i18n="@@IRF_Footer_donator_disclaimer">
          Ovaj vebsajt je izrađen u okviru projekta koji finansijski podržava Britanska ambasada Podgorica. Sadržaj stranice je isključiva odgovornost autora i nužno ne odražava stavove donatora ovog projekta.
        </span>
      </div>
      <div class="w-full md:w-7/24">
        <img src="assets/img/logos/british_embassy.svg" alt="British Embassy" title="British Embassy" width="98"
             height="64">
      </div>
    </div>
  </div>
  <div class="w-full py-2 bg-azure-200">
    <div class="container">
      <span class="text-12 text-neutral-white">
        © {{ currentYear }}
        <span i18n="@@IRF_full_brand_name">Investiciono razvjoni fond Crne Gore</span>.
        <br *ngIf="isMobileLayout"/>
        <span i18n="@@all_rights_reserved">Sva prava zadržana</span>.
      </span>
    </div>
  </div>
</footer>
