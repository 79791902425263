import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {ContentModuleBaseComponent} from '../content-module-base.component';
import {ContentModule} from '../../models/content-module.model';
import {faChevronDoubleDown} from '@fortawesome/pro-duotone-svg-icons/faChevronDoubleDown';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-content-module-a010',
  templateUrl: './content-module-a010.component.html',
  styleUrls: ['./content-module-a010.component.scss']
})

export class ContentModuleA010Component implements ContentModuleBaseComponent {
  @Input() contentModule: ContentModule;

  faChevronDoubleDown = faChevronDoubleDown;

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  scrollToBottom(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const anchor = document.getElementById('a10-anchor-' + this.contentModule.id);

    if (anchor) {
      anchor.scrollIntoView({behavior: 'smooth'});
    }
  }
}
