import {
  Component,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { ContentModuleBaseComponent } from '../content-module-base.component';
import { ContentModule } from '../../models/content-module.model';

@Component({
  selector: 'app-content-module-b010',
  templateUrl: './content-module-b010.component.html'
})

export class ContentModuleB010Component implements ContentModuleBaseComponent {
  @Input() contentModule: ContentModule;

  constructor(
    @Inject(PLATFORM_ID) public platformId
  ) {
  }

}
