<section class="w-full relative relative my-16 max-w-[1440px] mx-auto" style="color: var(--page-accent);"
  [ngClass]="{ 'bg-neutral-white' : contentModule.is_highlighted }">
  <div class="w-full h-full grid grid-cols-24 relative md:min-h-[340px]">
    <div class="container md:w-full col-span-24 md:col-span-13 md:col-end-13 aspect-[4/3] relative">
      <img *ngIf="contentModule.image"
         class="md:absolute md:inset-0 md:object-center md:object-cover w-full h-full z-10 aspect-[4/3]"
         width="904"
         height="678"
         srcset="{{ contentModule.image?.i_390x292 }} 390w, {{ contentModule.image?.i_904x678 }} 904w"
         sizes="(max-width: 768px) 390px, (min-width: 768px) 904px"
         alt="{{ contentModule.name }}"
         title="{{ contentModule.name }}"
         [src]="contentModule.image?.i_904x678">
    </div>
  </div>
  <div class="relative mt-8 md:mt-0 md:absolute md:inset-0 md:z-20">
    <div class="container mx-auto h-full grid gap-4 grid-cols-6 md:grid-cols-24">
      <div class="grid col-span-6 md:col-span-12 md:col-start-13 lg:col-start-14 lg:col-span-12 h-full content-center">
        <div class="swiper swiper-module-a31 w-full">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let slotable of contentModule.slotables">
              <app-slot-a30 [slotable]="slotable" [slotType]="contentModule.slot_type"></app-slot-a30>
            </div>
          </div>
        </div>
        <div class="relative mt-4">
          <div class="flex flex-row items-center justify-between">
            <div class="relative flex-1">
              <div class="flex flex-row space-x-2 swiper-pagination swiper-pagination-a31"></div>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <button class="w-10 h-10 text-neutral-400 hover:text-neutral-900 hover:cursor-pointer transition-colors ease-in-out nav-left-a31">
                <fa-icon class="text-24" [icon]="['fal', 'arrow-left']"></fa-icon>
              </button>
              <button class="w-10 h-10 text-neutral-400 hover:text-neutral-900 hover:cursor-pointer transition-colors ease-in-out nav-right-a31">
                <fa-icon class="text-24" [icon]="['fal', 'arrow-right']"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
