<div class="container mb-20">
  <h2 class="inline-flex text-18 md:text-22 text-primary font-bold mb-6">Solid icons</h2>
  <div class="flex flex-col md:flex-row md:flex-wrap">
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon1 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon2 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon3 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'chevron-up']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon4 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'circle-info']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon5 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon6 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'hands-holding-circle']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon7 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'house-building']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon8 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'person-carry-box']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon9 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'print']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon10 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'rocket-launch']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon11 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'square-check']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon12 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'tractor']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon13 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'umbrella-beach']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon14 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fas', 'user']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon15 }}</code>
        </pre>
    </div>
  </div>
</div>

<div class="container mb-20">
  <h2 class="inline-flex text-18 md:text-22 text-primary font-bold mb-6">Light icons</h2>
  <div class="flex flex-col md:flex-row md:flex-wrap">
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'location-dot']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon16 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'phone']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon17 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'user']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon18 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'envelope']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon19 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'hands-holding-circle']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon20 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'rocket-launch']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon21 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'tractor']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon22 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'umbrella-beach']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon23 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'house-building']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon24 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'circle-info']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon25 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'language']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon26 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'circle-minus']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon27 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'minus']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon28 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'circle-plus']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon29 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'magnifying-glass']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon30 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'arrow-up']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon31 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon32 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'arrow-down']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon33 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'arrow-left']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon34 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'plus']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon35 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'circle-question']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon36 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'xmark']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon37 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'lightbulb-on']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon38 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'arrows-down-to-people']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon39 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'handshake']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon40 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'chart-line-up']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon41 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'user-tie-hair-long']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon42 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'user-tag']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon43 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'bars']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon44 }}</code>
        </pre>
    </div>
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['fal', 'xmark-large']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon45 }}</code>
        </pre>
    </div>
  </div>
</div>

<div class="container mb-20">
  <h2 class="inline-flex text-18 md:text-22 text-primary font-bold mb-6">Regular icons</h2>
  <div class="flex flex-col md:flex-row md:flex-wrap">
    <div class="w-full md:w-12/24 mb-4 px-4">
      <fa-icon [icon]="['far', 'check']"></fa-icon>
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="text-12 leading-125">{{ icon46 }}</code>
        </pre>
    </div>
  </div>
</div>







