import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { Adapter } from '../../../Library/adapter/adapter';
import { DocumentListItem, DocumentListItemAdapter } from './document-list-item.model';

export class DocumentList extends Resource<Request> {

  id?: number;
  title: string;
  description: string;
  items: DocumentListItem[];
  is_adapted?: boolean;

  public constructor(data?: Partial<DocumentList>) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DocumentListAdapter implements Adapter<DocumentList> {

  constructor(
    private documentListItemAdapter: DocumentListItemAdapter
  ) {
  }

  adapt(data: any): DocumentList {
    const item = data.data;

    if (item.items) {
      item.items = this.documentListItemAdapter.adaptArray(item.items);
    }

    if (item.is_adapted) {
      return item;
    }
    item.is_adapted = true;


    return item;
  }

  adaptArray(items: any): DocumentList[] {
    items = (items.data.data) ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({data: item});
    });
  }

}
