import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ds-buttons',
  templateUrl: './ds-buttons.component.html',
  styleUrls: ['./ds-buttons.component.scss']
})
export class DsButtonsComponent implements OnInit {

  btnPrimary: string;
  btnPrimaryDisabled: string;
  btnPrimaryIcon: string;

  btnSecondary: string;
  btnSecondaryDisabled: string;
  btnSecondaryIcon: string;

  btnLink: string;
  btnLinkDisabled: string;
  btnLinkIcon: string;

  btnSpecialLinkIcon: string;


  constructor() {
  }

  ngOnInit(): void {
    this.btnPrimary = `<button class="btn btn__primary">Btn primary</button>`;
    this.btnPrimaryDisabled = `<button class="btn btn__primary" disabled>Btn primary</button>`;

    this.btnSecondary = `<button class="btn btn__secondary">Btn secondary</button>`;
    this.btnSecondaryDisabled = `<button class="btn btn__secondary" disabled>Btn secondary</button>`;

    this.btnLink = `<button class="btn__link">Btn link</button>`;
    this.btnLinkDisabled = `<button class="btn__link" disabled>Btn link</button>`;

    this.btnPrimaryIcon = `
<button class="btn btn__primary">
  <span>Btn primary</span>
  <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
</button>`;
    this.btnSecondaryIcon = `
<button class="btn btn__secondary">
  <span>Btn secondary</span>
  <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
</button>`;

    this.btnLinkIcon = `
<button class="btn__link">
  <span>Btn secondary</span>
  <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
</button>`;

this.btnSpecialLinkIcon = `
    <button class="btn__link--special group">
    <fa-icon [icon]="['fal', 'arrow-right']" class="btn__link--special-icon"></fa-icon>
    <span>Btn link</span>
  </button>`;

  }
}
