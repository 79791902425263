import { AfterViewInit, Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { isPlatformBrowser } from '@angular/common';
import Swiper, { Navigation, Pagination } from 'swiper';

@Component({
  selector: 'app-content-module-a040',
  templateUrl: './content-module-a040.component.html'
})
export class ContentModuleA040Component implements OnInit, AfterViewInit {

  @Input() contentModule: ContentModule;
  isMobileLayout: boolean = null;
  swiper: Swiper;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobileLayout = window.innerWidth < 768;
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobileLayout = window.innerWidth <= 768;
      window.addEventListener('resize', () => {
        this.isMobileLayout = window.innerWidth <= 768;
      });
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.swiper = new Swiper('.swiper-module-a40', {
        modules: [Navigation, Pagination],
        slidesPerView: 1.25,
        spaceBetween: 16,
        breakpoints: {
          768: {
            slidesPerView: 4,
            spaceBetween: 20
          },
        }
      });
    }
  }

}
