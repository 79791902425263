import { Component, Input, OnInit } from '@angular/core';
import { ContentModuleBaseComponent } from '../content-module-base.component';
import { ContentModule } from '../../models/content-module.model';

@Component({
  selector: 'app-content-module-a020',
  templateUrl: './content-module-a020.component.html'
})
export class ContentModuleA020Component implements ContentModuleBaseComponent, OnInit {
  @Input() contentModule: ContentModule;

  constructor() {
  }

  ngOnInit(): void {

  }
}
