import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../@shared/shared.module';
import { RouterModule } from '@angular/router';

import { EnquiriesService } from '../../Library/contact/services/enquiries.service';
import { ReactiveFormsModule } from '@angular/forms';

import { ContentModuleDirective } from './directives/content-module.directive';
import { ContentModuleComponent } from './components/content-module.component';

import { ContentModuleA010Component } from './components/content-module-a010/content-module-a010.component';
import { ContentModuleA020Component } from './components/content-module-a020/content-module-a020.component';
import { ContentModuleA030Component } from './components/content-module-a030/content-module-a030.component';
import { ContentModuleA031Component } from './components/content-module-a031/content-module-a031.component';
import { ContentModuleA032Component } from './components/content-module-a032/content-module-a032.component';
import { ContentModuleA033Component } from './components/content-module-a033/content-module-a033.component';
import { ContentModuleA040Component } from './components/content-module-a040/content-module-a040.component';
import { ContentModuleA050Component } from './components/content-module-a050/content-module-a050.component';
import { ContentModuleA051Component } from './components/content-module-a051/content-module-a051.component';
import { ContentModuleA060Component } from './components/content-module-a060/content-module-a060.component';
import { ContentModuleA061Component } from './components/content-module-a061/content-module-a061.component';
import { ContentModuleA062Component } from './components/content-module-a062/content-module-a062.component';
import { ContentModuleA070Component } from './components/content-module-a070/content-module-a070.component';
import { ContentModuleA080Component } from './components/content-module-a080/content-module-a080.component';
import { ContentModuleA090Component } from './components/content-module-a090/content-module-a090.component';
import { ContentModuleA091Component } from './components/content-module-a091/content-module-a091.component';
import { ContentModuleA092Component } from './components/content-module-a092/content-module-a092.component';
import { ContentModuleA100Component } from './components/content-module-a100/content-module-a100.component';
import { ContentModuleA110Component } from './components/content-module-a110/content-module-a110.component';
import { ContentModuleA111Component } from './components/content-module-a111/content-module-a111.component';
import { ContentModuleB010Component } from './components/content-module-b010/content-module-b010.component';
import { SlotA10Component } from './components/slot-a10/slot-a10.component';
import { SlotA20Component } from './components/slot-a20/slot-a20.component';
import { SlotA30Component } from './components/slot-a30/slot-a30.component';
import { SlotA32Component } from './components/slot-a32/slot-a32.component';
import { SlotA40Component } from './components/slot-a40/slot-a40.component';
import { SlotA50Component } from './components/slot-a50/slot-a50.component';
import { SlotA51Component } from './components/slot-a51/slot-a51.component';
import { SlotA60Component } from './components/slot-a60/slot-a60.component';
import { SlotA70Component } from './components/slot-a70/slot-a70.component';
import { SlotA80Component } from './components/slot-a80/slot-a80.component';
import { SlotA90Component } from './components/slot-a90/slot-a90.component';
import { SlotA91Component } from './components/slot-a91/slot-a91.component';
import { SlotA92Component } from './components/slot-a92/slot-a92.component';
import { SlotB10Component } from './components/slot-b10/slot-b10.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'swiper/angular';
import { SlotA110Component } from './components/slot-a110/slot-a110.component';

@NgModule({
  declarations: [
    ContentModuleDirective,
    ContentModuleComponent,
    ContentModuleA010Component,
    ContentModuleA020Component,
    ContentModuleA030Component,
    ContentModuleA031Component,
    ContentModuleA032Component,
    ContentModuleA033Component,
    ContentModuleA040Component,
    ContentModuleA050Component,
    ContentModuleA051Component,
    ContentModuleA060Component,
    ContentModuleA061Component,
    ContentModuleA062Component,
    ContentModuleA070Component,
    ContentModuleA080Component,
    ContentModuleA090Component,
    ContentModuleA091Component,
    ContentModuleA092Component,
    ContentModuleA100Component,
    ContentModuleA110Component,
    ContentModuleA111Component,
    ContentModuleB010Component,
    SlotA10Component,
    SlotA20Component,
    SlotA30Component,
    SlotA32Component,
    SlotA40Component,
    SlotA50Component,
    SlotA51Component,
    SlotA60Component,
    SlotA70Component,
    SlotA80Component,
    SlotA90Component,
    SlotA91Component,
    SlotA92Component,
    SlotB10Component,
    SlotA110Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    SwiperModule
  ],
  exports: [
    ContentModuleComponent
  ],
  providers: [
    EnquiriesService
  ]
})
export class ContentModuleModule {
  constructor() {
  }
}
