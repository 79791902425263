<div class="w-full h-module-a010 relative">
  <div class="absolute inset-0 overflow-hidden">
    <picture *ngIf="contentModule.image">
      <source srcset="{{ contentModule.image.i_1920x1440 }}" media="(min-width: 768px)">
      <source srcset="{{ contentModule.image.i_780x1170 }}" media="(max-width: 768px)">
      <img class="absolute inset-0 object-center object-cover w-full h-full"
           srcset="{{ contentModule.image.i_390x585 }}"
           alt="{{ contentModule.image.alt }}">
    </picture>

    <picture *ngIf="!contentModule.image">
      <source srcset="assets/img/default/irf-default-me-i_1920x1440.jpg" media="(min-width: 768px)">
      <source srcset="assets/img/default/irf-default-me-i_780x1170.jpg" media="(max-width: 768px)">
      <img class="absolute inset-0 object-center object-cover w-full h-full"
           srcset="assets/img/default/irf-default-me-i_780x1170.jpg"
           alt="Investiciono-razvojni fond Crne Gore">
    </picture>

    <div class="absolute inset-0 bg-neutral-black/20"></div>
  </div>

  <div class="absolute inset-0 flex flex-col justify-end md:justify-start">
    <div class="container md:flex-1">
      <div class="h-full flex items-center pt-10">
        <h1 *ngIf="!contentModule.name_hidden"
            class="text-neutral-white font-700 font-bitter text-40 md:text-64 lg:text-80 drop-shadow-[1px_1px_2px_rgba(0,0,0,0.15)]">
          {{ contentModule.name }}
        </h1>
      </div>
    </div>
    <div
      class="w-full pt-10 pb-11 md:pb-5 bg-gradient-to-t from-neutral-black/60 to-transparent">
      <div class="container flex flex-col items-center gap-8">
        <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div *ngFor="let slotable of contentModule.slotables">
            <app-slot-a10 [slotable]="slotable" [slotType]="contentModule.slot_type"></app-slot-a10>
          </div>
        </div>
        <button
          class="hidden md:block text-neutral-white text-24 pt-0 pb-1 hover:pt-1 hover:pb-0 hover:translate-y-1 transition-transform"
          (click)="scrollToBottom()">
          <fa-icon [icon]="faChevronDoubleDown"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<div id="a10-anchor-{{ contentModule.id }}" class="scroll-mt-[57px]"></div>
