<div class="text-neutral-900">
  <h3 class="text-14 text-azure-100 mb-4 uppercase pr-8">{{ slotable.supertitle }}</h3>
  <h2 class="text-28 md:text-22 lg:text-30 font-700 border-l-8 pl-3 lg:pl-6 lg:pr-8 mb-4 font-bitter"
      [style.border-color]="slotable.loan_category_color ? slotable.loan_category_color : 'var(--page-accent)'">
    {{ slotable.title }}
  </h2>
  <p class="text-16 md:text-14 lg:text-18 mb-8 lg:pr-6"
     [innerHTML]="slotable.description"></p>
  <a *ngIf="slotType !== 'custom-html'"
     title="{{ slotable.title }}" [appFlLink]="slotable.url"
     class="btn btn__primary md:inline-flex">
    <span i18n="@@general_learn_more_btn">Saznajte više</span>
    <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
  </a>
</div>
