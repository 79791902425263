import {
  ComponentRef,
  Directive,
  ElementRef, HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appFlLink]',
})
export class FlLinkDirective implements OnInit {

  @Input() appFlLink: string;

  @HostListener('click', ['$event']) onClick($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    if (this.appFlLink !== undefined) {
      if (this.isInternal(this.appFlLink)) {
        // if absolute URL, remove domain for routerLink
        if (this.appFlLink.startsWith(environment.websiteUrl)) {
          this.appFlLink = this.appFlLink.replace(environment.websiteUrl, '');
        }
        if (this.appFlLink.startsWith('/en/')) {
          this.appFlLink = this.appFlLink.replace('/en/', '/');
        }
        const linkComponents = this.appFlLink.split('?');
        const link = linkComponents[0];
        let params = null;
        if (linkComponents[1]) {
          params = JSON.parse('{"' + decodeURI(linkComponents[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        }
        this.router.navigate([link], {
          queryParams: params,
        });
      } else {
        window.open(this.appFlLink, '_blank');
      }
    }
  }

  constructor(
    private elementRef: ElementRef,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.appFlLink !== undefined) {
      this.elementRef.nativeElement.href = this.appFlLink;
      if (!this.isInternal(this.appFlLink)) {
        this.elementRef.nativeElement.classList.add('link-external');
      }
    } else {
      this.elementRef.nativeElement.classList.add('hover:no-underline', 'hover:cursor-pointer');
    }
  }

  isInternal(url: string): boolean {
    return url.startsWith(environment.websiteUrl) || !url.startsWith('http');
  }
}
