import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DesignSystemComponent } from './components/design-system/design-system.component';
import { DesignSystemRoutingModule } from './design-system-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DsButtonsComponent } from './components/ds-buttons/ds-buttons.component';
import { DsInputFieldsComponent } from './components/ds-input-fields/ds-input-fields.component';
import { DsIconsComponent } from './components/ds-icons/ds-icons.component';


@NgModule({
  declarations: [
    DesignSystemComponent,
    DsButtonsComponent,
    DsInputFieldsComponent,
    DsIconsComponent,
  ],
  exports: [
    DesignSystemComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    DesignSystemRoutingModule,
    ReactiveFormsModule
  ]
})
export class DesignSystemModule { }
