import { Component, Input, OnInit } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-b10',
  templateUrl: './slot-b10.component.html',
  styleUrls: ['./slot-b10.component.scss']
})
export class SlotB10Component implements OnInit {

  @Input() slotable: Slot;
  faArrowRight = faArrowRight;

  constructor() {
  }

  ngOnInit(): void {
  }

}
