import { Component, Input } from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a70',
  templateUrl: './slot-a70.component.html',
  styleUrls: ['./slot-a70.component.scss']
})
export class SlotA70Component {

  @Input() slotable: Slot;

  constructor() {
  }

}
