<div class="relative z-200">
  <div *ngIf="isPlatformMobile" class="block md:hidden">
    <app-header-mobile [headerMenu]="headerMenu"></app-header-mobile>
  </div>

  <div *ngIf="!isPlatformMobile">
    <div class="block md:hidden">
      <app-header-mobile [headerMenu]="headerMenu"></app-header-mobile>
    </div>
    <div class="hidden md:block">
      <app-header-desktop [headerMenu]="headerMenu"></app-header-desktop>
    </div>
  </div>
</div>
