import Bowser, { Parser } from 'bowser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
@Injectable({
  providedIn: 'root'
})
export class Platform {

  bowser = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    @Optional() @Inject(REQUEST) private request: Request
  ) { }

  isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  isMobile(): boolean {
    return this.getBowser().getPlatformType(true) === 'mobile';
  }

  getBowser(): Parser.Parser {
    if (!this.bowser) {
      const userAgentString = this.isBrowser() ? window.navigator.userAgent : this.request.header('User-Agent');
      this.bowser = Bowser.getParser(userAgentString);
    }

    return this.bowser;
  }

}
