import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class CalculatorInterestRate extends Resource<Request> {
  id: number;
  title: string;
  interest_rate: number;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CalculatorInterestRateAdapter extends BaseAdapter<CalculatorInterestRate> {

  constructor() {
    super(CalculatorInterestRate);
  }

  adapt(data: any): CalculatorInterestRate {
    return super.adapt(data);
  }

  adaptArray(items: any): CalculatorInterestRate[] {
    items = (items.data.data) ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({data: item});
    });
  }
}
