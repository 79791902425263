import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { Resource as ResourceInterface } from '../../../Library/resource/resource';
import { Adapter } from '../../../Library/adapter/adapter';

export class LoanTranslation extends Resource<Request> implements ResourceInterface<LoanTranslation> {

  id?: number;
  title?: string;
  link?: string;

  public constructor(data?: {}) {
    super(data);
    this.init();
  }

  init(): void {
  }
}

@Injectable({
  providedIn: 'root',
})
export class LoanTranslationAdapter implements Adapter<LoanTranslation> {

  constructor() {}

  adapt(data: any): LoanTranslation {
    const item = data.data;
    return new LoanTranslation(item);
  }

  adaptArray(items: any): LoanTranslation[] {
    items = items.data.data;
    return items.map((item) => {
      return this.adapt({ data: item });
    });
  }
}
