<section class="relative w-full my-16" style="color: var(--page-accent);">
    <header *ngIf="!contentModule.name_hidden" class="w-full px-4 md:px-0 md:container block w-full mb-12">
      <h1 class="text-28 md:text-30 font-700 text-neutral-900 font-bitter">{{ contentModule.name }}</h1>
    </header>
    <div class="w-full pl-4 md:pl-0 md:container md:grid md:gap-4 md:grid-cols-24">
      <div *ngFor="let slotable of contentModule.slotables"
           class="col-span-6 md:col-span-8 slot-a80">
        <app-slot-a80 [slotable]="slotable"></app-slot-a80>
      </div>
    </div>
</section>
