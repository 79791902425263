import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ds-input-fields',
  templateUrl: './ds-input-fields.component.html',
  styleUrls: ['./ds-input-fields.component.scss']
})
export class DsInputFieldsComponent implements OnInit {

  inputFieldRegular: string;
  inputFieldDisabled: string;
  inputFieldError: string;
  selectFieldRegular: string;
  selectFieldDisabled: string;
  selectFieldError: string;

  constructor() { }

  ngOnInit(): void {
    this.inputFieldRegular = `
<div class="ds-input-field__wrapper">
  <label class="ds-field-label" id="ds-field-label" for="ds-field">
    Input field label <span class="text-error"><sup>*</sup></span>
  </label>
  <input
    class="ds-field-input"
    id="ds-field"
    name="ds-field"
    type="text"
    autocomplete="off"
    placeholder="Input field placeholder">
  <span class="ds-field-msg">Input field disclaimer</span>
</div>`;

    this.inputFieldDisabled = `
<div class="ds-input-field__wrapper">
  <label class="ds-field-label" id="ds-field-label" for="ds-field">
    Input field label <span class="text-error"><sup>*</sup></span>
  </label>
  <input
    class="ds-field-input"
    id="ds-field"
    name="ds-field"
    type="text"
    disabled
    autocomplete="off"
    placeholder="Input field placeholder">
  <span class="ds-field-msg">Input field disclaimer</span>
</div>`;

    this.inputFieldError = `
<div class="ds-input-field__wrapper has-error">
  <label class="ds-field-label" id="ds-field-label" for="ds-field">
    Input field label <span class="text-error"><sup>*</sup></span>
  </label>
  <input
    class="ds-field-input"
    id="ds-field"
    name="ds-field"
    type="text"
    autocomplete="off"
    placeholder="Input field placeholder">
  <span class="ds-field-msg">Input field disclaimer</span>
</div>`;

    this.selectFieldRegular = `
<div class="ds-input-field__wrapper">
  <label class="ds-field-label" for="ds-field-select">
    Input field label <span class="text-error"><sup>*</sup></span>
  </label>
  <select name="ds-field-select" id="ds-field-select" class="ds-field-input">
    <option>One</option>
    <option>Two</option>
    <option>Three</option>
  </select>
  <span class="ds-field-msg">Input field disclaimer</span>
</div>`;

        this.selectFieldDisabled = `
<div class="ds-input-field__wrapper">
  <label class="ds-field-label" for="ds-field-select">
    Input field label <span class="text-error"><sup>*</sup></span>
  </label>
  <select disabled name="ds-field-select" id="ds-field-select" class="ds-field-input">
    <option>One</option>
    <option>Two</option>
    <option>Three</option>
  </select>
  <span class="ds-field-msg">Input field disclaimer</span>
</div>`;

            this.selectFieldError = `
<div class="ds-input-field__wrapper has-error">
  <label class="ds-field-label" for="ds-field-select">
    Input field label <span class="text-error"><sup>*</sup></span>
  </label>
  <select name="ds-field-select" id="ds-field-select" class="ds-field-input">
    <option>One</option>
    <option>Two</option>
    <option>Three</option>
  </select>
  <span class="ds-field-msg">Input field disclaimer</span>
</div>`;

  }

}
