import {Component, Input} from '@angular/core';
import {Slot} from '../../models/slot.model';

@Component({
  selector: 'app-slot-a10',
  templateUrl: './slot-a10.component.html'
})
export class SlotA10Component {

  @Input() slotable: Slot;
  @Input() slotType: string;

  constructor() {
  }
}
