<section class="relative w-full my-16"
         style="color: var(--page-accent);">
  <header *ngIf="!contentModule.name_hidden"
          class="container block w-full mb-12 flex justify-between items-center">
    <h1 class="text-28 md:text-30 font-700 text-neutral-900 font-bitter">
      {{ contentModule.name }}
    </h1>
    <a *ngIf="!isMobileLayout && contentModule.slot_type === 'article' && contentModule.data_source === 'automatic'"
       [routerLink]="searchRoute"
       [queryParams]="queryParams"
       class="btn btn__primary">
      <span i18n="@@IRF_Module_Show_All">Vidi sve</span>
      <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
    </a>
  </header>

  <div class="container grid gap-4 grid-cols-6 md:grid-cols-24">
    <div *ngFor="let slotable of contentModule.slotables"
         class="col-span-6 border-b border-b-neutral-400 md:border-none mb-2 md:mb-0">
      <app-slot-a50 [slotable]="slotable"></app-slot-a50>
    </div>
  </div>
  <a *ngIf="isMobileLayout && contentModule.slot_type === 'article' && contentModule.data_source === 'automatic'"
     [routerLink]="searchRoute"
     [queryParams]="queryParams"
     class="btn btn__primary container mt-12">
    <span i18n="@@IRF_Module_Show_All">Pogledaj sve</span>
    <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
  </a>
</section>
