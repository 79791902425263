import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { isPlatformBrowser } from '@angular/common';
import { currentLocale, routes as routesTrans } from '../../../../Library/routes';

@Component({
  selector: 'app-content-module-a050',
  templateUrl: './content-module-a050.component.html'
})
export class ContentModuleA050Component implements OnInit {

  @Input() contentModule: ContentModule;

  searchRoute: string = `/${routesTrans[currentLocale].search}`;
  queryParams: any;

  isMobileLayout: boolean = null;

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit(): void {
    if (this.contentModule.data_source === 'automatic') {
      this.getSearchQueryParams();
    }

    if (isPlatformBrowser(this.platformId)) {
      this.isMobileLayout = window.innerWidth <= 768;
      window.addEventListener('resize', () => {
        this.isMobileLayout = window.innerWidth <= 768;
      });
    }
  }

  getSearchQueryParams(): void {
    this.queryParams = {
      type: 'articles',
      orderby: 'published_at',
      order: 'desc',
      page: '1',
      limit: '6',
      offset: '0'
    };

    for (const objectId of this.contentModule.data_source_object_ids) {
      switch (objectId.object) {

        case 'Tag':
          this.queryParams.tag_id = objectId.ids.join('|');
          break;

        case 'ArticleType':
          this.queryParams.article_type_id = objectId.ids.join('|');
          break;

      }
    }
  }

}
