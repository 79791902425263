import {Component, Input, OnInit} from '@angular/core';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-slot-a110',
  templateUrl: './slot-a110.component.html'
})
export class SlotA110Component implements OnInit {

  @Input() slotable: any;

  faArrowRight = faArrowRight;

  constructor() {
  }

  ngOnInit(): void {
  }

}
