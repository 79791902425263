import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { SeoItem } from './seo-item.model';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { currentLocale, RouteName } from '../../routes';
import { LocaleService } from '../locale.service';
import { SeoRobotsService } from './seo-robots.service';
import { Meta } from '@angular/platform-browser';
import { SeoOgService } from './seo-og.service';
import { SeoTwitterService } from './seo-twitter.service';
import { SeoGeneralService } from './seo-general.service';
import { SeoRichDataService } from './seo-rich-data.service';
import { seoRulesDefault } from '../../../@modules/routing/seo-rules/seo-rules-default';
import { HasSeoItem, hasSeoItem } from './has-seo-item';
import { Resource } from '../../resource/resource.model';
import { seoRules } from '../../seo-rules';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private currentRoute: RouteName = null;
  public currentPage: string = null;
  public seoItem: SeoItem = null;
  public currentObject: Resource<any> = null;
  public defaultText = 'Investiciono razvojni fond crne Gore - IRFCG';

  constructor(
    private seoGeneralService: SeoGeneralService,
    private seoOgService: SeoOgService,
    private seoTwitterService: SeoTwitterService,
    private seoRobotsService: SeoRobotsService,
    private seoRichDataService: SeoRichDataService,
    private metaTagService: Meta,
    private router: Router,
    private localeService: LocaleService,
    @Inject(LOCALE_ID) locale
  ) {
    this.defaultText = 'Investiciono razvojni fond crne Gore - IRFCG';
    switch (locale) {
      case 'en':
        this.defaultText = 'Investment and Development Fund of Montenegro';
        break;
    }
  }

  init(): void {
    this.seoOgService.setDefaultText(this.defaultText);
    this.seoTwitterService.setDefaultText(this.defaultText);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.reset();
      }
    });
  }

  setCurrentRoute(routeName: RouteName): void {
    this.currentRoute = routeName;
    this.seoRichDataService.setCurrentRoute(routeName);
    this.setSeoItem();
  }

  setSeoItem(seoItem: SeoItem = null): void {
    if (seoItem === null && seoRules[this.currentRoute]) {
      this.seoItem = new SeoItem(seoRules[this.currentRoute][this.localeService.getLocale()]);
      this.seoGeneralService.setSeoItem(this.seoItem);
      this.seoOgService.setSeoItem(this.seoItem);
      this.seoTwitterService.setSeoItem(this.seoItem);
      this.seoRobotsService.setSeoItem(this.seoItem);
    } else if (seoItem) {
      this.seoItem = seoItem;
      this.seoGeneralService.setSeoItem(this.seoItem);
      this.seoOgService.setSeoItem(this.seoItem);
      this.seoTwitterService.setSeoItem(this.seoItem);
      this.seoRobotsService.setSeoItem(this.seoItem);
    }
  }

  getSeoItem(): SeoItem {
    return this.seoItem;
  }

  setCurrentObject(currentObject: Resource<any>): void {
    this.currentObject = currentObject;

    this.seoGeneralService.setCurrentObject(this.currentObject);
    this.seoOgService.setCurrentObject(this.currentObject);
    this.seoTwitterService.setCurrentObject(this.currentObject);
    this.seoRobotsService.setCurrentObject(this.currentObject);
    this.seoRichDataService.setCurrentObject(this.currentObject);
  }

  getCurrentObject(): Resource<any> {
    return this.currentObject;
  }

  refreshForRoute(routeSnapshot: ActivatedRouteSnapshot): void {

    this.reset();

    if (!routeSnapshot.data) {
      this.refreshWithDefault();
    }

    const routeData = routeSnapshot.data;
    let seoItem;

    if (routeData && routeData.seoItem) {
      seoItem = routeData.seoItem;
    } else if (routeData.item && hasSeoItem(routeData.item)) {
      seoItem = routeData.item.getSeoItem();
    }

    if (seoItem) {
      this.refresh(seoItem);
    } else {
      this.refreshWithDefault();
    }
  }

  refresh(seoItem: SeoItem): void {
    this.seoGeneralService.refresh(seoItem);
    this.seoOgService.refresh(seoItem);
    this.seoTwitterService.refresh(seoItem);
    this.seoRobotsService.refresh(seoItem);
    this.seoRichDataService.refresh();
  }

  refreshWithDefault(): void {
    const seoItem = new SeoItem({
      title: seoRulesDefault[currentLocale].title,
      description: seoRulesDefault[currentLocale].description,
      keywords: seoRulesDefault[currentLocale].keywords
    });
    this.refresh(seoItem);
  }

  reset(): void {
    this.seoGeneralService.reset();
    this.seoOgService.reset();
    this.seoTwitterService.reset();
    this.seoRobotsService.reset();
    this.seoRichDataService.reset();
  }
}
