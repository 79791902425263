<section style="color: var(--page-accent);" class="my-16">

  <header *ngIf="!contentModule.name_hidden" class="w-full container block mb-12">
    <h1 class="text-28 md:text-30 font-700 text-neutral-900 font-bitter">
      {{ contentModule.name }}
    </h1>
  </header>

  <div class="module-a40 container md:w-full md:max-w-[1440px]">
    <div class="swiper swiper-module-a40 h-84 md:h-[518px]">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let slotable of contentModule.slotables">
          <app-slot-a40 [slotable]="slotable"></app-slot-a40>
        </div>
      </div>
    </div>
  </div>

</section>
