<a class="w-full relative h-full flex flex-col justify-between group hover:cursor-pointer shadow-sm rounded-8 p-4 bg-neutral-white hover:border-neutral-400 border border-neutral-white transition-all ease-in-out"
   [appFlLink]="slotable.url" title="{{ slotable.title }}">
  <div class="flex flex-col space-y-1">
    <h3 class="text-neutral-900 group-hover:text-azure-200 group-hover:underline transition-all ease-in-out text-18 md:text-16 lg:text-22 font-700 line-clamp-2">
      {{ slotable.title }}
    </h3>
    <div [innerHTML]="slotable.description"
      class="text-neutral-900 text-18 md:text-16 lg:text-14 font-400 line-clamp-3">
    </div>
  </div>
</a>
