<div class="relative h-full group">
  <a [appFlLink]="slotable.url" style="color:var(--page-accent);"
    class="flex items-center h-full w-full">
    <img class="flex m-none absolute inset-0 object-cover object-center w-full h-full"
         src="{{ slotable.image.i_390x585 }}"
         alt="{{ slotable.title }}" loading="lazy">
    <h3 class="px-4 py-6 bg-neutral-900/40 group-hover:bg-neutral-900/60 text-neutral-white group-hover:underline uppercase text-20 md:text-18 lg:text-22 font-700 z-20 relative w-full">
      {{ slotable.title }}
    </h3>
  </a>
</div>
